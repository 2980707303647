import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

import App from './app/App';
import PersistedStore from './store/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { showUpdateAvailable } from 'features/user/userSlice';
import './index.css';
import './i18n'; // Import the i18n configuration

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Create a root using createRoot
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={PersistedStore.store}>
      <PersistGate loading={null} persistor={PersistedStore.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

if (window.Cypress) {
  window.store = PersistedStore.store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Dispatch an action to your store to notify the app about the update
    PersistedStore.store.dispatch(showUpdateAvailable(registration));
  },
});
