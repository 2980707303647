import http from '../http';

//CARERS
export async function getCarers(payload) {
  const response = await http.get(`users/${payload}/caregivers`);
  return response;
}

export async function assignCarer(payload) {
  const { patient_id, caregiver_id } = payload;
  const response = await http.patch(`users/${patient_id}/caregivers`, {
    caregiver_id,
  });
  return response;
}

export async function deleteCarer(payload) {
  const { patient_id, caregiver_id } = payload;
  const response = await http.delete(`users/${patient_id}/caregivers`, {
    data: {
      caregiver_id,
    },
  });
  return response;
}

//STEPS
export async function getSteps(payload) {
  const { id, from, to } = payload;
  const response = await http.get(`users/${id}/steps`, {
    params: {
      from,
      to,
      group_by_day: true,
    },
  });
  return response;
}

//GLYCEMIA
const getTimeSeries = async (measure, payload) => {
  const { from, to, patientId, page } = payload;

  // Early return for the case where `from` is not provided and the entire payload is used
  if (!from) {
    return await http.get(payload);
  }

  // Construct the base URL and parameters
  let url = `users/${patientId}/${measure}`;
  let params = { from, to };
  if (page) {
    params.page = page;
  }

  return await http.get(url, { params });
};

export async function getGlycemiaByDate(payload) {
  return getTimeSeries('glycemia', payload);
}

export async function getAGP(payload) {
  const { from, to, patientId } = payload;
  const response = await http.get(`users/${patientId}/glycemia/data`, {
    params: { from, to },
  });
  return response;
}

export async function postglycemiaFile(payload) {
  const { userId, file, type } = payload;
  const formData = new FormData();

  formData.append('glycemia_file', file);

  const params = {
    type,
    timezone: 'America/Santiago',
  };

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  try {
    const response = await http.post(
      `users/${userId}/glycemia/files`,
      formData,
      { params, headers },
    );
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

//MEASURES
export async function getWeight(payload) {
  return getTimeSeries('weight', payload);
}

export async function getIMC(payload) {
  return getTimeSeries('imc', payload);
}

export async function getCholesterol(payload) {
  return getTimeSeries('cholesterol', payload);
}

export async function postCholesterol(payload) {
  const { id, value1, value2, ...rest } = payload;
  return await http.post(`users/${id}/cholesterol`, {
    ldl_cholesterol: value1,
    hdl_cholesterol: value2,
    ...rest,
  });
}

export async function getPressure(payload) {
  return getTimeSeries('pressure', payload);
}

export async function postPressure(payload) {
  const { id, value1, value2, ...rest } = payload;
  return await http.post(`users/${id}/pressure`, {
    diastolic_pressure: value1,
    sistolic_pressure: value2,
    ...rest,
  });
}

export async function getBodyMeasuresByDate(payload) {
  const response = await http.get(payload);
  return response;
}

//GOALS
export async function getHemoglobinGoals(payload) {
  const response = await http.get(
    `patients/${payload}/glycated-hemoglobin-goals`,
    {
      params: {
        light: true,
      },
    },
  );
  return response;
}
export async function getHemoglobinGoal(payload) {
  const response = await http.get(`glycated-hemoglobin-goals/${payload}`);
  return response;
}
export async function postHemoglobinGoal(payload) {
  const response = await http.post(`glycated-hemoglobin-goals`, payload);
  return response;
}
export async function deleteHemoglobineGoal(payload) {
  const response = await http.delete(`glycated-hemoglobin-goals/${payload}`);
  return response;
}

export async function getStepsGoals(payload) {
  const response = await http.get(`patients/${payload}/steps-goals`);
  return response;
}
export async function postStepGoal(payload) {
  const response = await http.post(`steps-goals`, payload);
  return response;
}
export async function deleteStepGoal(payload) {
  const response = await http.delete(`steps-goals/${payload}`);
  return response;
}

//FEET
export async function getFeetImages(payload) {
  const { patientId, page } = payload;
  const response = await http.get(`patients/${patientId}/diabetic-feet`, {
    params: {
      page,
    },
  });
  return response;
}

export async function getFeetImage(payload) {
  const { id, prediction } = payload;
  const params = prediction ? { prediction: true } : {};
  const response = await http.get(`diabetic-feet/${id}/image`, {
    blob: true,
    params,
  });
  return response;
}
export async function getFeetMetadata(payload) {
  const response = await http.get(`diabetic-feet/${payload}`);
  return response;
}
export async function getFeetPrediction(payload) {
  const response = await http.get(`diabetic-feet/${payload}/prediction`);
  return response;
}

export async function editFeet(payload) {
  const { id, ...rest } = payload;
  const response = await http.patch(`diabetic-feet/${id}`, rest);
  return response;
}

//MEALS
export async function mealsEntries(payload) {
  const { patientId, from, to, page } = payload;
  const response = await http.get(`users/${patientId}/meals_entry`, {
    params: {
      from,
      to,
      page, // Added page parameter here
    },
  });
  return response;
}

export async function getMealImage(payload) {
  const { id } = payload;
  const response = await http.get(`meals_entry/${id}/image`, {
    blob: true,
  });
  return response;
}

//EXAMS
export async function getBloodTests(payload) {
  const { id, from, to, page } = payload;
  const response = await http.get(`users/${id}/blood_test`, {
    params: {
      from,
      to,
      page,
    },
  });
  return response;
}

//HEALTH FACILITIES
export async function getHealthFacilities(payload) {
  const response = await http.get(`patients/${payload}/health-facilities`);
  return response;
}

export async function assignHealthFacilities(payload) {
  const { userId, healthFacilities } = payload;
  const response = await http.post(`patients/${userId}/health-facilities`, {
    health_facilities: healthFacilities,
  });
  return response;
}

export async function unassignHealthFacility(payload) {
  const { userId, healthFacility_id } = payload;
  const response = await http.delete(
    `patients/${userId}/health-facilities/${healthFacility_id}`,
  );
  return response;
}
