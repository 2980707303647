import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getMedicalServices,
  getMedicalService,
  getRegionalMedicalServices,
  getRegionalMedicalService,
  getHealthFacilities,
  getHealthFacility,
  getHealthFacilityAdmins,
  getHealthFacilityProfessionals,
  getHealthFacilityPatients,
  unassignUser,
  assignUser,
} from 'api/medicalServices';

import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const medicalServicesState = {
  nationalServices: {
    list: [],
    current: null,
    error: false,
  },
  regionalServices: {
    list: [],
    current: null,
    error: false,
  },
  facilities: {
    list: [],
    current: {
      data: null,
      patients: [],
      professionals: [],
      admins: [],
      error: false,
    },
    error: false,
  },
};

// MEDICAL SERVICES
const fetchMedicalServices = createAsyncThunk(
  'medicalServices/fetchMedicalServices',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getMedicalServices,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.nationalServices.list,
    );
  },
);

const fetchMedicalService = createAsyncThunk(
  'medicalServices/fetchMedicalService',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getMedicalService,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

// REGIONAL MEDICAL SERVICES
const fetchRegionalMedicalServices = createAsyncThunk(
  'medicalServices/fetchRegionalMedicalServices',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getRegionalMedicalServices,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.regionalServices.list,
    );
  },
);

const fetchRegionalMedicalService = createAsyncThunk(
  'medicalServices/fetchRegionalMedicalService',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getRegionalMedicalService,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

// HEALTH FACILITIES
const fetchHealthFacilities = createAsyncThunk(
  'medicalServices/fetchHealthFacilities',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilities,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.facilities.list,
    );
  },
);

const fetchHealthFacility = createAsyncThunk(
  'medicalServices/fetchHealthFacility',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacility,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

// HEALTH FACILITY -- ADMINS
const fetchHealthFacilityAdmins = createAsyncThunk(
  'medicalServices/fetchHealthFacilityAdmins',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilityAdmins,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.facilities.current.admins,
    );
  },
);

// HEALTH FACILITY -- PROFESSIONALS
const fetchHealthFacilityProfessionals = createAsyncThunk(
  'medicalServices/fetchHealthFacilityProfessionals',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilityProfessionals,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.facilities.current.professionals,
    );
  },
);

// HEALTH FACILITY -- PATIENTS
const fetchHealthFacilityPatients = createAsyncThunk(
  'medicalServices/fetchHealthFacilityPatients',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilityPatients,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      medicalServicesState.facilities.current.patients,
    );
  },
);

// HEALTH FACLITY -- USERS
const fetchAssignUser = createAsyncThunk(
  'health-facility/assignUser',
  async (payload, thunkAPI) => {
    const response = await assignUser(payload);
    return errorHandler(response.status, response.data);
  },
);

const fetchUnassignUser = createAsyncThunk(
  'health-facility/unassignUser',
  async (payload, thunkAPI) => {
    const response = await unassignUser(payload);
    return errorHandler(response.status, response.data);
  },
);

// HELPER FUNCTIONS
const updateAssignState = (state, payload, user_type) => {
  const key = user_type + 's';
  const currentList = [...state.facilities.current[key]];
  const userAlreadyOnList = currentList.some((user) => user.id === payload.id);
  if (!userAlreadyOnList) {
    currentList.push(payload);
  }
  state.facilities.current[key] = currentList;
};

const updateUnassignState = (state, payload, user_type) => {
  const key = user_type + 's';
  const currentList = [...state.facilities.current[key]];
  const index = currentList.findIndex((obj) => obj.id === payload.id);
  currentList.splice(index, 1);
  state.facilities.current[key] = currentList;
};

// SLICE
const medicalServicesSlice = createSlice({
  name: 'medicalServices',
  initialState: medicalServicesState,
  reducers: {
    cleanHealthFacility: (state, action) => {
      state.facilities.current = {
        data: null,
        patients: [],
        professionals: [],
        admins: [],
        error: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedicalServices.fulfilled, (state, action) => {
        state.nationalServices.list = action.payload;
      })
      .addCase(fetchMedicalServices.rejected, (state, action) => {
        state.nationalServices.error = true;
      })
      .addCase(fetchRegionalMedicalServices.fulfilled, (state, action) => {
        state.regionalServices.list = action.payload;
      })
      .addCase(fetchRegionalMedicalServices.rejected, (state, action) => {
        state.regionalServices.error = true;
      })
      .addCase(fetchHealthFacilities.fulfilled, (state, action) => {
        state.facilities.list = action.payload;
      })
      .addCase(fetchHealthFacilities.rejected, (state, action) => {
        state.facilities.error = true;
      })
      .addCase(fetchHealthFacility.fulfilled, (state, action) => {
        state.facilities.current.data = action.payload;
      })
      .addCase(fetchHealthFacility.rejected, (state, action) => {
        state.facilities.current.error = true;
      })
      .addCase(fetchHealthFacilityAdmins.fulfilled, (state, action) => {
        state.facilities.current.admins = action.payload;
      })
      .addCase(fetchHealthFacilityAdmins.rejected, (state, action) => {
        state.facilities.current.error = true;
      })
      .addCase(fetchHealthFacilityProfessionals.fulfilled, (state, action) => {
        state.facilities.current.professionals = action.payload;
      })
      .addCase(fetchHealthFacilityProfessionals.rejected, (state, action) => {
        state.facilities.current.error = true;
      })
      .addCase(fetchHealthFacilityPatients.fulfilled, (state, action) => {
        state.facilities.current.patients = action.payload;
      })
      .addCase(fetchHealthFacilityPatients.rejected, (state, action) => {
        state.facilities.current.error = true;
      })
      .addCase(fetchAssignUser.fulfilled, (state, action) => {
        updateAssignState(state, action.payload, action.meta.arg.user_type);
      })
      .addCase(fetchAssignUser.rejected, (state, action) => {
        state.facilities.current.error = true;
      })
      .addCase(fetchUnassignUser.fulfilled, (state, action) => {
        updateUnassignState(state, action.payload, action.meta.arg.user_type);
      })
      .addCase(fetchUnassignUser.rejected, (state, action) => {
        state.facilities.current.error = true;
      });
  },
});

export const fetchMedicalServiceThunk = fetchMedicalService;
export const fetchMedicalServicesThunk = fetchMedicalServices;
export const fetchRegionalMedicalServiceThunk = fetchRegionalMedicalService;
export const fetchRegionalMedicalServicesThunk = fetchRegionalMedicalServices;
export const fetchHealthFacilityThunk = fetchHealthFacility;
export const fetchHealthFacilitiesThunk = fetchHealthFacilities;
export const fetchHealthFacilityAdminsThunk = fetchHealthFacilityAdmins;
export const fetchHealthFacilityProfessionalsThunk =
  fetchHealthFacilityProfessionals;
export const fetchHealthFacilityPatientsThunk = fetchHealthFacilityPatients;
export const { cleanHealthFacility } = medicalServicesSlice.actions;
export const fetchAssignUserThunk = fetchAssignUser;
export const fetchUnassignUserThunk = fetchUnassignUser;
export const medicalServicesReducer = medicalServicesSlice.reducer;
