import http from '../http';

// MEDICAL SERVICES
export async function getMedicalServices() {
  const medicalServices = await http.get(`medical-services`);
  return medicalServices;
}

export async function getMedicalService(payload) {
  const medicalService = await http.get(`medical-services/${payload}`);
  return medicalService;
}

export async function createMedicalService(payload) {
  return await http.post('medical-services', payload);
}

export async function editMedicalService(payload) {
  const path = `medical-services/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteMedicalService(payload) {
  const path = `medical-services/${payload}`;
  return await http.delete(path);
}

// REGIONAL MEDICAL SERVICES
export async function getRegionalMedicalServices() {
  const regionalMedicalServices = await http.get(`regional-medical-services`);
  return regionalMedicalServices;
}

export async function getRegionalMedicalService(payload) {
  const regionalMedicalService = await http.get(
    `regional-medical-services/${payload}`,
  );
  return regionalMedicalService;
}

export async function createRegionalMedicalService(payload) {
  return await http.post('regional-medical-services', payload);
}

export async function editRegionalMedicalService(payload) {
  const path = `regional-medical-services/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteRegionalMedicalService(payload) {
  const path = `regional-medical-services/${payload}`;
  return await http.delete(path);
}

// HEALTH FACILITIES
export async function getHealthFacilities(payload) {
  const { page } = payload;
  const healthFacilities = await http.get(`health-facilities`, {
    params: {
      page,
    },
  });
  return healthFacilities;
}

export async function getHealthFacility(payload) {
  const healthFacility = await http.get(`health-facilities/${payload}`);
  return healthFacility;
}

export async function createHealthFacility(payload) {
  return await http.post('health-facilities', payload);
}

export async function editHealthFacilities(payload) {
  const path = `health-facilities/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteHealthFacilities(payload) {
  const path = `health-facilities/${payload}`;
  return await http.delete(path);
}

// HEALTH FACILITY -- ADMINS
export async function getHealthFacilityAdmins(payload) {
  const admins = await http.get(`health-facilities/${payload}/admins`);
  return admins;
}

export async function assignAdmin(payload) {
  const { healthFacility_id, admin } = payload;
  const response = await http.post(
    `health-facilities/${healthFacility_id}/admins`,
    {
      admin,
    },
  );
  return response;
}

// HEALTH FACILITY -- PROFESSIONALS
export async function getHealthFacilityProfessionals(payload) {
  const professionals = await http.get(
    `health-facilities/${payload}/professionals`,
  );
  return professionals;
}

// HEALTH FACILITY -- PATIENTS
export async function getHealthFacilityPatients(payload) {
  const patients = await http.get(`health-facilities/${payload}/patients`);
  return patients;
}

// HEALTH FACILITY -- USERS

export async function assignUser(payload) {
  const { facility_id, userId, user_type } = payload;
  const response = await http.post(
    `health-facilities/${facility_id}/${user_type}s`,
    {
      [user_type]: userId,
    },
  );
  return response;
}

export async function unassignUser(payload) {
  // Valid types are: patients, professionals & admins
  const { facility_id, userId, user_type } = payload;
  const response = await http.delete(
    `health-facilities/${facility_id}/${user_type}s/${userId}`,
  );
  return response;
}
