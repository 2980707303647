import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProfessionalPatients,
  assignPatient,
  unassignPatient,
  getHealthFacilities,
  assignHealthFacilities,
  unassignHealthFacility,
} from 'api/professionals';
import { getUser, getUsers } from 'api/users';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { editUser } from 'api/users/index';

export const professionalsState = {
  list: [],
  selected: {
    data: null,
    patients: [],
    facilities: [],
  },
  error: false,
};

const fetchProfessional = createAsyncThunk(
  'professional/getProfessional',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      professionalsState.selected,
    );
  },
);

const fetchProfessionalPatients = createAsyncThunk(
  'professional/patients',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getProfessionalPatients,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      professionalsState.selected.patients,
    );
  },
);

const fetchAssignPatient = createAsyncThunk(
  'professionals/assignPatient',
  async (payload, thunkAPI) => {
    const response = await assignPatient(payload);
    return response.data;
  },
);

const fetchUnassignPatient = createAsyncThunk(
  'professionals/unassignPatient',
  async (payload, thunkAPI) => {
    const response = await unassignPatient(payload);
    return response.data;
  },
);

const editProfessionalInfo = createAsyncThunk(
  'edit/professional',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const result = await thunkhandler(
      editUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    // thunkAPI.dispatch(fetchProfessional(result.data.UserId));
    return result.data;
  },
);

const fetchProfessionals = createAsyncThunk(
  'professionals',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const params = { is_professional: true, is_light: true };
    const response = await thunkhandler(
      getUsers,
      params,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(
      response.status,
      response.data,
      professionalsState.list,
    );
  },
);

const fetchProfessionalHealthFacilities = createAsyncThunk(
  'professional/health-facilities',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    let result = await thunkhandler(
      getHealthFacilities,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(result.status, result.data);
  },
);

const fetchAssignHealthFacilities = createAsyncThunk(
  'professional/assign-health-facility',
  async (payload, thunkAPI) => {
    const response = await assignHealthFacilities(payload);
    return response.data;
  },
);

const fetchUnassignHealthFacility = createAsyncThunk(
  'professional/unassign-health-facility',
  async (payload, thunkAPI) => {
    const response = await unassignHealthFacility(payload);
    return response.data;
  },
);

// HELPER FUNCTIONS
const assignHealthFacilityHelper = (state, payload) => {
  const currentList = [...state.selected.facilities];

  for (let newFacility of payload) {
    const alreadyOnList = currentList.some(
      (facility) => facility.id === newFacility.id,
    );

    if (!alreadyOnList) {
      currentList.push(newFacility);
    }
  }

  state.selected.facilities = currentList;
};

const unassignHealthFacilityHelper = (state, payload) => {
  const unassignedItem = payload;
  const index = state.selected.facilities.findIndex(
    (obj) => obj.id === unassignedItem.id,
  );
  state.selected.facilities.splice(index, 1);
};

const professionalsSlice = createSlice({
  name: 'professionals',
  initialState: professionalsState,
  reducers: {
    cleanProfessional: (state, action) => {
      state.selected = {
        data: null,
        patients: [],
        facilities: [],
      };
    },
    removeProfessional: (state, action) => {
      state.list = state.list.filter(
        (professional) => professional.id !== action.payload,
      );
    },
    updateProfessional: (state, action) => {
      // Find the index of the professional to update
      const index = state.list.findIndex(
        (professional) => professional.id === action.payload.id,
      );
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
    removeMyPatient: (state, action) => {
      state.selected.patients = state.selected.patients.filter(
        (patient) => patient.id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfessional.fulfilled, (state, action) => {
        state.selected.data = action.payload;
        state.error = false;
      })
      .addCase(fetchProfessional.rejected, (state, action) => {
        state.selected.data = null;
        state.error = true;
      })
      .addCase(fetchProfessionalPatients.fulfilled, (state, action) => {
        state.selected.patients = action.payload;
        state.error = false;
      })
      .addCase(fetchProfessionalPatients.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(fetchAssignPatient.fulfilled, (state, action) => {
        const existingPatientIndex = state.selected.patients.findIndex(
          (patient) => patient.id === action.payload.id,
        );

        if (existingPatientIndex === -1) {
          state.selected.patients.push({
            ...action.payload,
            id: action.payload.id,
          });
        }
      })
      .addCase(fetchUnassignPatient.fulfilled, (state, action) => {
        const unassignedPatient = action.payload.id;
        const index = state.selected.patients.findIndex(
          (obj) => obj.id === unassignedPatient.id,
        );
        state.selected.patients.splice(index, 1);
      })
      .addCase(fetchProfessionals.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchProfessionals.rejected, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(editProfessionalInfo.fulfilled, (state, action) => {
        state.selected.data = {
          is_loading: false,
          info: action.payload,
        };
      })
      .addCase(fetchProfessionalHealthFacilities.fulfilled, (state, action) => {
        state.selected.facilities = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProfessionalHealthFacilities.rejected, (state, action) => {
        state.selected.facilities = [];
        state.error = true;
      })
      .addCase(fetchAssignHealthFacilities.fulfilled, (state, action) => {
        assignHealthFacilityHelper(state, action.payload);
      })
      .addCase(fetchUnassignHealthFacility.fulfilled, (state, action) => {
        unassignHealthFacilityHelper(state, action.payload);
      });
  },
});

export const fetchProfessionalThunk = fetchProfessional;
export const fetchProfessionalPatientsThunk = fetchProfessionalPatients;
export const fetchProfessionalsThunk = fetchProfessionals;
export const fetchAssignPatientThunk = fetchAssignPatient;
export const fetchUnassignPatientThunk = fetchUnassignPatient;
export const editProfessionalInfoThunk = editProfessionalInfo;
export const fetchProfessionalHealthFacilitiesThunk =
  fetchProfessionalHealthFacilities;
export const fetchAssignHealthFacilitiesThunk = fetchAssignHealthFacilities;
export const fetchUnassignHealthFacilityThunk = fetchUnassignHealthFacility;
export const {
  cleanProfessional,
  updateProfessional,
  removeProfessional,
  removeMyPatient,
} = professionalsSlice.actions;
export const professionalsReducer = professionalsSlice.reducer;
