import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBloodTests } from 'api/patients/index';
import thunkHandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

// el estado es una matriz donde cada fila es una página
// cada página tiene los grupo de exámenes correspondientes en el formato que viene desde la API.
export const initialState = {
  list: [],
  error: false,
};

// export const initialState = {
//   list: [
//     [
//       {
//         biochemical_profile_entry: {
//           timestamp: '2021-12-30T15:41:04Z',
//           timezone: 'America/Santiago',
//           glycemia: 0,
//           ureic_nitrogen: 0,
//           calcium: 0,
//           phosphorus: 0,
//           total_proteins: 0,
//           albumin: 0,
//           uric_acid: 0,
//           alkaline_phosphatases: 0,
//           total_bilirubin: 0,
//           ldh: 0,
//           got_ast: 0,
//         },
//         hematology_entry: {
//           timestamp: '2021-12-30T15:41:04Z',
//           timezone: 'America/Santiago',
//           blood_type: 'AB+',
//           rh_d: '+',
//         },
//       },
//     ],
//     [
//       {
//         biochemical_profile_entry: {
//           timestamp: '2021-12-31T15:41:04Z',
//           timezone: 'America/Santiago',
//           glycemia: 0,
//           ureic_nitrogen: 0,
//           calcium: 0,
//           phosphorus: 0,
//           total_proteins: 0,
//           albumin: 0,
//           uric_acid: 0,
//           alkaline_phosphatases: 0,
//           total_bilirubin: 0,
//           ldh: 0,
//           got_ast: 0,
//         },
//       },
//     ],
//   ],
//   error: false,
// };

const fetchBloodTest = createAsyncThunk(
  'exams/fetchBloodTest',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const { data, status } = await thunkHandler(
      getBloodTests,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(status, { ...data, page: payload.page }, []);
  },
);

const examsSlice = createSlice({
  name: 'exams',
  initialState: initialState,
  reducers: {
    // Your reducers here, if any
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBloodTest.fulfilled, (state, action) => {
        const { page, ...rest } = action.payload;
        state.error = false;
        state.list[page - 1] = rest.results;
        state.prev = rest.previous ? true : false;
        state.next = rest.next ? true : false;
      })
      .addCase(fetchBloodTest.rejected, (state, action) => {
        state.error = true;
      });
    // Add more cases as needed
  },
});

export const fetchBloodTestThunk = fetchBloodTest;
export const examsReducer = examsSlice.reducer;
