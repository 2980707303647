import http from '../http';

//PROFESSIONALS
export async function editProfessional(payload) {
  const response = await http.patch(
    `professionals/${payload.id}`,
    payload.data,
  );
  return response;
}

export async function getProfessionalPatients(payload) {
  const response = await http.get(`users/${payload}/patients`, {
    params: {
      light: true,
    },
  });
  return response;
}

//PATIENTS
export async function assignPatient(payload) {
  const { patient_id, professional_id } = payload;
  const response = await http.patch(`users/${professional_id}/patients`, {
    patient_id,
  });
  return response;
}

export async function unassignPatient(payload) {
  const { patient_id, professional_id } = payload;
  const response = await http.delete(`users/${professional_id}/patients`, {
    data: {
      patient_id,
    },
  });
  return response;
}

//HEALTH FACILITIES
export async function getHealthFacilities(payload) {
  const response = await http.get(`professionals/${payload}/health-facilities`);
  return response;
}

export async function assignHealthFacilities(payload) {
  const { userId, healthFacilities } = payload;
  const response = await http.post(
    `professionals/${userId}/health-facilities`,
    {
      health_facilities: healthFacilities,
    },
  );
  return response;
}

export async function unassignHealthFacility(payload) {
  const { userId, healthFacility_id } = payload;
  const response = await http.delete(
    `professionals/${userId}/health-facilities/${healthFacility_id}`,
  );
  return response;
}
