import React from 'react';
import { NavLink } from 'react-router-dom';
import './_NavLinks.scss';

const StandaloneAdminNavLinks = ({ currentUser, closeDrawer }) => {
  const navItems = [
    {
      path: '/standalone-admins/',
      label: 'STANDALONE ADMINS',
    },
  ];

  return (
    <>
      {navItems.map((item, index) => (
        <li key={index}>
          <NavLink to={item.path} onClick={closeDrawer}>
            {item.label}
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default StandaloneAdminNavLinks;
