import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMealImage, mealsEntries } from 'api/patients/index';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const initialState = {
  data: {},
  error: false,
};

const fetchMeals = createAsyncThunk(
  'users/mealsRegister',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;

    // Initial request to get the first page
    const initialResponse = await thunkhandler(
      mealsEntries,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    let { next, results } = initialResponse.data;
    let page = 2;

    // Fetch all subsequent pages
    while (next) {
      const paginatedResponse = await mealsEntries({ ...payload, page });
      results = results.concat(paginatedResponse.data.results);
      next = paginatedResponse.data.next;
      page += 1;
    }

    return errorHandler(initialResponse.status, results, initialState.list);
  },
);

const fetchMealImage = createAsyncThunk(
  'patients/fetchMealImage',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getMealImage,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    const urlCreator = window.URL || window.webkitURL;
    const imageUrl =
      response &&
      response.status !== 503 &&
      urlCreator.createObjectURL(response.data);
    return imageUrl;
  },
);

const mealsRegisterSlice = createSlice({
  name: 'meals',
  initialState: initialState,
  reducers: {
    cleanState: (state, action) => {
      const { data, error } = initialState;
      state.data = data;
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeals.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = false;
      })
      .addCase(fetchMeals.rejected, (state, action) => {
        state.data = action.payload;
        state.error = true;
      })
      .addCase(fetchMealImage.fulfilled, (state, action) => {
        state.error = false;

        const mealIndex = state.data.results.findIndex(
          (meal) => meal.id === action.meta.arg.id,
        );
        if (mealIndex !== -1) {
          state.data.results[mealIndex].blob = action.payload;
        }
      })
      .addCase(fetchMealImage.rejected, (state, action) => {
        state.error = true;
      });
    // Add more cases as needed
  },
});

export const mealsReducer = mealsRegisterSlice.reducer;
export const mealsActions = mealsRegisterSlice.actions;
export const fetchMealsThunk = fetchMeals;
export const fetchMealImageThunk = fetchMealImage;
