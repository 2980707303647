import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  assignCarer,
  getCarers,
  deleteCarer,
  getSteps,
  getGlycemiaByDate,
  postglycemiaFile,
  getWeight,
  getIMC,
  getHemoglobinGoals,
  getStepsGoals,
  postHemoglobinGoal,
  postStepGoal,
  getFeetImages,
  getFeetPrediction,
  getAGP,
  getFeetImage,
  getFeetMetadata,
  editFeet,
  deleteHemoglobineGoal,
  deleteStepGoal,
  getCholesterol,
  getPressure,
  postCholesterol,
  postPressure,
  getHealthFacilities,
  assignHealthFacilities,
  unassignHealthFacility,
} from 'api/patients/';
import {
  createUser,
  editUser,
  getUser,
  getUsers,
  findUser,
} from 'api/users/index';
import { getPatientIntakes } from 'api/prescriptions';
import {
  fetchPatientTreatmentsThunk,
  postTreatmentThunk,
} from '../patientProfile/profile/profileOptions/treatments/treatmentSlice';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { DateTime } from 'luxon';

const measuresSchema = {
  count: 0,
  results: [],
};
export const initialState = {
  error: false,
  list: [],
  current: {
    patient: {},
    carers: [],
    allergies: [],
    treatments: [],
    goals: {
      hemoglobin: [],
      steps: [],
    },
    measures: {
      steps: {
        error: false,
        results: [],
      },
      glycemia: {
        error: false,
        tir: null,
        dgp: null,
      },
      body: {
        error: false,
        weight: measuresSchema,
        imc: measuresSchema,
        cholesterol: measuresSchema,
        pressure: measuresSchema,
      },
      intakes: {
        error: false,
        list: [],
      },
    },
    feet: {
      next: null,
      prev: null,
      list: [],
      current: {},
    },
    facilities: {
      list: [],
      unassign: null,
    },
  },
};

const fetchMeasureByDate = async (payload, callback, thunkAPI) => {
  const { from, to, patientId } = payload;
  const token = thunkAPI.getState().user.data.refresh;
  const initialResponse = await thunkhandler(
    callback,
    payload,
    thunkAPI,
    refreshSessionThunk,
    token,
  );

  let { next, results, count } = initialResponse.data;
  let page = 2;

  while (next) {
    const paginatedResponse = await callback({ from, to, patientId, page });
    results = results.concat(paginatedResponse.data.results);
    next = paginatedResponse.data.next;
    page += 1;
  }

  return { count, results };
};

const fetchSearchPatient = createAsyncThunk(
  'users/search',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const result = await thunkhandler(
      findUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return result.data;
  },
);

const fetchPatients = createAsyncThunk(
  'patients/fetchPatients',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const params = { is_patient: true, is_light: true };
    const response = await thunkhandler(
      getUsers,
      params,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, initialState.list);
  },
);

const carerAssignment = createAsyncThunk(
  'patients/assignCarer',
  async (payload, thunkAPI) => {
    const { patientId, ...carerPayload } = payload;
    let { carerId, ...rest } = carerPayload;
    let carer;
    const token = thunkAPI.getState().user.data.refresh;
    if (!carerId) {
      carer = await thunkhandler(
        createUser,
        rest,
        thunkAPI,
        refreshSessionThunk,
        token,
      );
      carerId = carer.data.id;
    } else {
      carer = await thunkhandler(
        getUser,
        carerId,
        thunkAPI,
        refreshSessionThunk,
        token,
      );
    }

    const assignmentPayload = { patient_id: patientId, caregiver_id: carerId };
    await thunkhandler(
      assignCarer,
      assignmentPayload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return carer.data;
  },
);

const removeCarer = createAsyncThunk(
  'patients/removeCarer',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const carer = await thunkhandler(
      deleteCarer,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return carer.data;
  },
);

const fetchCarers = createAsyncThunk(
  'patients/fetchCarers',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCarers,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const fetchHemoglobinGoals = createAsyncThunk(
  'patients/fetchHemGoals',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHemoglobinGoals,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const fetchStepsGoals = createAsyncThunk(
  'patients/fetchStepsGoals',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getStepsGoals,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const addHemoglobinGoal = createAsyncThunk(
  'patients/postHemGoal',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      postHemoglobinGoal,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      initialState.current.goals.hemoglobin,
    );
  },
);

const delHemoglobineGoal = createAsyncThunk(
  'patients/deleteHemGoal',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteHemoglobineGoal,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const addStepGoal = createAsyncThunk(
  'patients/postStepGoal',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      postStepGoal,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);
const delStepGoal = createAsyncThunk(
  'patients/deleteStepGoal',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteStepGoal,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const getInfo = createAsyncThunk(
  'patients/getInfo',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const currentUser = thunkAPI.getState().user.data;
    const patient = await thunkhandler(
      getUser,
      payload.id,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    thunkAPI.dispatch(fetchCarers(patient.data.id));

    if (payload.full_access) {
      thunkAPI.dispatch(fetchPatientTreatmentsThunk(patient.data.id));
      thunkAPI.dispatch(fetchHemoglobinGoals(patient.data.id));
      thunkAPI.dispatch(fetchStepsGoals(patient.data.id));
    }

    if (currentUser.is_superadmin || currentUser.is_health_facility_admin) {
      thunkAPI.dispatch(fetchHealthFacilities(patient.data.id));
    }

    return errorHandler(patient.status, patient.data);
  },
);

const editPatient = createAsyncThunk(
  'patients/editPatient',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    let patient;
    patient = await thunkhandler(
      editUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(patient.status, patient.data);
  },
);

const fetchSteps = createAsyncThunk(
  'patients/fetchSteps',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getSteps,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      initialState.current.measures.steps,
    );
  },
);

const fetchGlycemiaAGP = createAsyncThunk(
  'patients/fetchGlycemia',
  async (payload, thunkAPI) => {
    const { from, to, patientId } = payload;

    // Basic validation of input parameters
    if (!from || !to || !patientId) {
      return thunkAPI.rejectWithValue(
        'Invalid parameters: from, to, and patientId are required.',
      );
    }

    // Calls another function to handle paginated responses
    const response = await fetchMeasureByDate(
      payload,
      getGlycemiaByDate,
      thunkAPI,
    );

    const agp_data = await getAGP({ from, to, patientId });

    return errorHandler(
      agp_data.status !== 200 ? agp_data.status : response.status,
      [response, agp_data.data],
      [response, []],
    );
  },
);

const submitGlycemiaFile = createAsyncThunk(
  'patients/submitGlycemiaFile',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      postglycemiaFile,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return response.status === 500 ? { error: 500 } : response.data;
  },
);

const measureCallback = async (callback, payload, thunkAPI) => {
  const { from, to, patientId } = payload;

  // Basic validation of input parameters
  if (!from || !to || !patientId) {
    return thunkAPI.rejectWithValue(
      'Invalid parameters: from, to, and patientId are required.',
    );
  }

  const response = await fetchMeasureByDate(payload, callback, thunkAPI);
  return errorHandler(response.status, response);
};

const fetchWeight = createAsyncThunk(
  'patients/fetchWeight',
  async (payload, thunkAPI) => {
    return measureCallback(getWeight, payload, thunkAPI);
  },
);

const fetchIMC = createAsyncThunk(
  'patients/fetchIMC',
  async (payload, thunkAPI) => {
    return measureCallback(getIMC, payload, thunkAPI);
  },
);

const fetchCholesterol = createAsyncThunk(
  'patients/fetchCholesterol',
  async (payload, thunkAPI) => {
    return measureCallback(getCholesterol, payload, thunkAPI);
  },
);

const submitCholesterol = createAsyncThunk(
  'patients/submitCholesterol',
  async (payload, thunkAPI) => {
    const response = await postCholesterol(payload);
    return response.data;
  },
);
const submitPressure = createAsyncThunk(
  'patients/submitPressure',
  async (payload, thunkAPI) => {
    const response = await postPressure(payload);
    return response.data;
  },
);
const fetchPressure = createAsyncThunk(
  'patients/fetchPressure',
  async (payload, thunkAPI) => {
    return measureCallback(getPressure, payload, thunkAPI);
  },
);

const fetchFeetImages = createAsyncThunk(
  'patients/fetchFeetImages',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getFeetImages,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    const predictions = response.data.results.map(async (e) => {
      const response = await thunkhandler(
        getFeetPrediction,
        e.id,
        thunkAPI,
        refreshSessionThunk,
        token,
      );
      return response.status === 200 ? response.data : null;
    });
    const blobs = response.data.results.map(async (e) => {
      const { data } = await thunkhandler(
        getFeetImage,
        { id: e.id, prediction: false },
        thunkAPI,
        refreshSessionThunk,
        token,
      );
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = data && data !== 503 && urlCreator.createObjectURL(data);
      return imageUrl;
    });
    const vals = await Promise.all(predictions);
    const blob_vals = await Promise.all(blobs);
    const results = response.data.results.map((e, index) => {
      return {
        ...e,
        prediction: vals[index] ? vals[index] : 0,
        blob: blob_vals[index],
      };
    });
    return {
      prev: response.data.previous,
      next: response.data.next,
      results,
    };
  },
);

const fetchFeetImage = createAsyncThunk(
  'patients/fetchFeetImage',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getFeetMetadata,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    const prediction = await thunkhandler(
      getFeetPrediction,
      response.data.id,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    const { data } = await thunkhandler(
      getFeetImage,
      { id: response.data.id, prediction: false },
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = data && data !== 503 && urlCreator.createObjectURL(data);
    return {
      ...response.data,
      prediction: prediction.status === 200 ? prediction.data : 0,
      blob: imageUrl,
    };
  },
);

const fetchImageGradCamp = createAsyncThunk(
  'patients/fetchImageGradCamp',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const { data } = await thunkhandler(
      getFeetImage,
      { id: payload, prediction: true },
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = data && data !== 503 && urlCreator.createObjectURL(data);
    return { blob: imageUrl, id: payload };
  },
);

const editImage = createAsyncThunk(
  'patients/editImage',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      editFeet,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.status !== 200
      ? Promise.reject(response.data)
      : response.data;
  },
);

const fetchPatientIntakes = createAsyncThunk(
  'patients/fetchIntakes',
  async (payload, thunkAPI) => {
    return measureCallback(getPatientIntakes, payload, thunkAPI);
  },
);

// HEALTH FACILTIES
const fetchHealthFacilities = createAsyncThunk(
  'patients/healthFacilities',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilities,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const fetchAssignHealthFacilities = createAsyncThunk(
  'patients/assign-health-facility',
  async (payload, thunkAPI) => {
    const response = await assignHealthFacilities(payload);
    return response.data;
  },
);

const fetchUnassignHealthFacility = createAsyncThunk(
  'patients/unassign-health-facility',
  async (payload, thunkAPI) => {
    const response = await unassignHealthFacility(payload);
    return response.data;
  },
);

// HELPER FUNCTIONS
const assignHealthFacilityHelper = (state, payload) => {
  const currentList = [...state.current.facilities.list];

  for (let newFacility of payload) {
    const alreadyOnList = currentList.some(
      (facility) => facility.id === newFacility.id,
    );

    if (!alreadyOnList) {
      currentList.push(newFacility);
    }
  }

  state.current.facilities.list = currentList;
};

const unassignHealthFacilityHelper = (state, payload) => {
  const unassignedItem = payload;
  const index = state.current.facilities.list.findIndex(
    (obj) => obj.id === unassignedItem.id,
  );
  state.current.facilities.list.splice(index, 1);
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState: initialState,
  reducers: {
    removePatient: (state, action) => {
      state.list = state.list.filter(
        (patient) => patient.id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.list = action.payload;
        state.error = false;
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.error = false;
        const { UserId, ...res } = action.payload;
        state.current.patient = { userId: UserId, ...res };
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(removeCarer.fulfilled, (state, action) => {
        state.current.carers = state.current.carers.filter(
          (carer) => carer.id !== action.payload.id,
        );
      })
      .addCase(carerAssignment.fulfilled, (state, action) => {
        const carer = action.payload;
        if (!state.current.carers.some((c) => c.id === carer.id)) {
          state.current.carers.push(carer);
        }
      })
      .addCase(fetchCarers.fulfilled, (state, action) => {
        state.current.carers = action.payload;
      })
      .addCase(editPatient.fulfilled, (state, action) => {
        const { weight, height } = state.current.patient;
        state.current.patient = { weight, height, ...action.payload };
      })
      .addCase(fetchSearchPatient.fulfilled, (state, action) => {
        state.search = state.list.find(
          (patient) => patient.id === action.payload.id,
        );
      })
      .addCase(fetchSearchPatient.rejected, (state, action) => {
        state.search = null;
      })
      .addCase(fetchSteps.fulfilled, (state, action) => {
        state.current.measures.steps.results = action.payload;
        state.current.measures.steps.error = false;
      })
      .addCase(fetchSteps.rejected, (state, action) => {
        state.current.measures.steps.error = true;
      })
      .addCase(fetchGlycemiaAGP.fulfilled, (state, action) => {
        const [response, agp_data] = action.payload;
        const { results, ...rest } = response;
        state.current.measures.glycemia.tir = {
          agp: agp_data,
          results: results,
        };

        const from = DateTime.fromISO(action.meta.arg.from);
        const to = DateTime.fromISO(action.meta.arg.to);

        const dgp_data = results.filter((g) => {
          const timestamp = DateTime.fromISO(g.timestamp);
          return from <= timestamp && to >= timestamp;
        });
        state.current.measures.glycemia.dgp = {
          results: dgp_data,
          ...rest,
        };
      })
      .addCase(fetchWeight.fulfilled, (state, action) => {
        const { error, weight, ...rest } = state.current.measures.body;
        state.current.measures.body = {
          error: false,
          weight: action.payload,
          ...rest,
        };
      })
      .addCase(fetchPatientIntakes.fulfilled, (state, action) => {
        state.current.measures.intakes = {
          error: false,
          list: action.payload.results,
        };
      })
      .addCase(fetchIMC.fulfilled, (state, action) => {
        const { error, imc, ...rest } = state.current.measures.body;
        state.current.measures.body = {
          error: false,
          imc: action.payload,
          ...rest,
        };
      })
      .addCase(fetchCholesterol.fulfilled, (state, action) => {
        const { error, cholesterol, ...rest } = state.current.measures.body;
        state.current.measures.body = {
          error: false,
          cholesterol: action.payload,
          ...rest,
        };
      })
      .addCase(submitCholesterol.fulfilled, (state, action) => {
        state.current.measures.body.cholesterol.count += 1;
        //state.current.measures.body.cholesterol.results.push(action.payload);
      })
      .addCase(submitPressure.fulfilled, (state, action) => {
        //state.current.measures.body.pressure.results.push(action.payload);
      })
      .addCase(fetchPressure.fulfilled, (state, action) => {
        const { error, pressure, ...rest } = state.current.measures.body;
        state.current.measures.body = {
          error: false,
          pressure: action.payload,
          ...rest,
        };
      })
      .addCase(fetchHemoglobinGoals.fulfilled, (state, action) => {
        state.current.goals.hemoglobin = action.payload;
      })
      .addCase(fetchStepsGoals.fulfilled, (state, action) => {
        state.current.goals.steps = action.payload;
      })
      .addCase(addHemoglobinGoal.fulfilled, (state, action) => {
        const newGoals = [...state.current.goals.hemoglobin, action.payload];
        state.current.goals.hemoglobin = newGoals;
      })
      .addCase(delHemoglobineGoal.fulfilled, (state, action) => {
        state.current.goals.hemoglobin = state.current.goals.hemoglobin.filter(
          (goal) => goal.id !== action.payload.id,
        );
      })
      .addCase(addStepGoal.fulfilled, (state, action) => {
        const newGoals = [...state.current.goals.steps, action.payload];
        state.current.goals.steps = newGoals;
      })
      .addCase(delStepGoal.fulfilled, (state, action) => {
        state.current.goals.steps = state.current.goals.steps.filter(
          (goal) => goal.id !== action.payload.id,
        );
      })
      .addCase(fetchFeetImages.fulfilled, (state, action) => {
        const { prev, next, results } = action.payload;
        state.current.feet.prev = prev ? true : null;
        state.current.feet.next = next ? true : null;
        state.current.feet.list = results.map((image) => {
          const { prediction, ...rest } = image;
          const prediction_parsed = {};
          Object.keys(prediction).forEach((key) => {
            prediction_parsed[key] = Number(prediction[key]);
          });
          return { ...rest, prediction: prediction_parsed };
        });
        state.current.feet.current = {};
      })
      .addCase(fetchFeetImage.fulfilled, (state, action) => {
        const { prediction, ...rest } = action.payload;
        const prediction_parsed = {};
        Object.keys(prediction).forEach((key) => {
          prediction_parsed[key] = Number(prediction[key]);
        });
        state.current.feet.current = { prediction: prediction_parsed, ...rest };
      })
      .addCase(fetchImageGradCamp.fulfilled, (state, action) => {
        const { blob, id } = action.payload;
        const list = state.current.feet.list;
        const currentImg = state.current.feet.current;

        // Find the index of the image in the list
        const imgIndex = list.findIndex((img) => img.id === id);

        // If the image is found in the list, update it in place
        if (imgIndex !== -1) {
          state.current.feet.list[imgIndex] = {
            ...list[imgIndex],
            gradCamp: blob,
          };
        } else {
          // Update the current image's gradCamp if it's not in the list
          currentImg.gradCamp = blob;
          state.current.feet.current = currentImg;
        }
      })
      .addCase(editImage.fulfilled, (state, action) => {
        const { blob, prediction } =
          Object.keys(state.current.feet.current).length === 0
            ? state.current.feet.list.find((f) => f.id === action.payload.id)
            : state.current.feet.current;
        const new_feet = {
          blob,
          prediction,
          ...action.payload,
        };
        const list = state.current.feet.list.filter(
          (f) => f.id !== action.payload.id,
        );
        list.push(new_feet);
        state.current.feet.current = new_feet;
        state.current.feet.list = list;
      })
      .addCase(fetchHealthFacilities.fulfilled, (state, action) => {
        state.current.facilities.list = action.payload;
      })
      .addCase(fetchAssignHealthFacilities.fulfilled, (state, action) => {
        assignHealthFacilityHelper(state, action.payload);
      })
      .addCase(fetchUnassignHealthFacility.fulfilled, (state, action) => {
        unassignHealthFacilityHelper(state, action.payload);
      })
      .addCase(fetchPatientTreatmentsThunk.fulfilled, (state, action) => {
        state.current.treatments = action.payload;
      })
      .addCase(postTreatmentThunk.fulfilled, (state, action) => {
        state.current.treatments.push(action.payload);
      });
  },
});

export const fetchSearchPatientThunk = fetchSearchPatient;
export const fetchPatientsThunk = fetchPatients;
export const fetchCarersThunk = fetchCarers;
export const editPatientThunk = editPatient;
export const getInfoThunk = getInfo;
export const removeCarerThunk = removeCarer;
export const assignCarerThunk = carerAssignment;
export const fetchStepsThunk = fetchSteps;
export const submitGlycemiaThunk = submitGlycemiaFile;
export const submitCholesterolThunk = submitCholesterol;
export const submitPressureThunk = submitPressure;
export const fetchWeightThunk = fetchWeight;
export const fetchIMCThunk = fetchIMC;
export const fetchCholesterolThunk = fetchCholesterol;
export const fetchPressureThunk = fetchPressure;
export const addHemoglobinGoalThunk = addHemoglobinGoal;
export const addStepGoalThunk = addStepGoal;
export const fetchFeetImagesThunk = fetchFeetImages;
export const fetchFeetImageThunk = fetchFeetImage;
export const fetchFeetImageGradCampThunk = fetchImageGradCamp;
export const editImageThunk = editImage;
export const fetchGlycemiaAGPThunk = fetchGlycemiaAGP;
export const deleteHemoglobineGoalThunk = delHemoglobineGoal;
export const deleteStepGoalThunk = delStepGoal;
export const fetchPatientIntakesThunk = fetchPatientIntakes;
export const fetchHealthFacilitiesThunk = fetchHealthFacilities;
export const fetchAssignHealthFacilitiesThunk = fetchAssignHealthFacilities;
export const fetchUnassignHealthFacilityThunk = fetchUnassignHealthFacility;
export const { removePatient } = patientsSlice.actions;
export const patientsReducer = patientsSlice.reducer;
