/**
 * Thunk Handler Function:
 *
 * This function handles asynchronous API calls within Redux Toolkit thunks.
 * - Manages retries and token refresh for secure API operations.
 * - Uses Redux Toolkit's createAsyncThunk API for consistent async action handling.
 * - Returns response data on successful API calls or rejects with error value on failure.
 */

const thunkHandler = async (apiCall, arg, thunkApi, refresh, token) => {
  try {
    // Attempt the initial API call
    let response = arg ? await apiCall(arg) : await apiCall();
    return response;
  } catch (error) {
    // Check if the error is a 401 Unauthorized
    if (error.response && error.response.status === 401) {
      try {
        // Attempt to refresh the token
        await thunkApi.dispatch(refresh(token));
        // Retry the initial API call with the new token
        let response = arg ? await apiCall(arg) : await apiCall();
        return response;
      } catch (refreshError) {
        // Handle token refresh failure
        return refreshError.response;
      }
    }

    return error.response;
  }
};

export default thunkHandler;
