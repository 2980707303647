import React from 'react';
import { NavLink } from 'react-router-dom';
import './_ProfileNavLinks.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const ProfileNavLinks = ({ dropdownOpen, setDropdownOpen, isOverflowing }) => {
  const dropdownItems = [
    { path: '/profile', label: 'MI PERFIL' },
    { path: '/logout', label: 'CERRAR SESIÓN' },
  ];

  const toggleDropdown = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <li className={`navbar-profile-dropdown`}>
        <button
          className="button is-light is-rounded is-link"
          onClick={toggleDropdown}
        >
          <FontAwesomeIcon
            icon={faUserCircle}
            className={`icon ${isOverflowing ? 'full-width' : ''}`}
          />
          {!isOverflowing && <span>CUENTA</span>}
        </button>
      </li>

      <li
        className={`navbar-profile-dropdown-links ${
          dropdownOpen ? 'dropdown-open' : ''
        }`}
      >
        {dropdownOpen && (
          <ul>
            {dropdownItems.map((item) => (
              <li key={item.path}>
                <NavLink to={item.path} onClick={toggleDropdown}>
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default ProfileNavLinks;
