import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from 'assets/img/logo-glik.webp';
import LanguageSelect from '../LanguageSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const sections = [
  { id: 'about-us', key: 'aboutUs' },
  { id: 'characteristics', key: 'characteristics' },
  { id: 'about-mobile', key: 'aboutMobile' },
  { id: 'about-web', key: 'aboutWeb' },
  { id: 'team', key: 'team' },
  { id: 'contact', key: 'contact' },
];

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('');
  const [isBurgerActive, setBurgerActive] = useState(false);
  const sectionRefs = useRef({});

  const memoizedSections = useMemo(() => sections, []);

  const determineActiveSection = useCallback(() => {
    const viewportHeight = window.innerHeight;
    const middleOfViewport = viewportHeight / 2;

    for (const section of memoizedSections) {
      const element = document.getElementById(section.id);
      if (!element) continue; // Error handling in case element is not found

      const rect = element.getBoundingClientRect();
      if (rect.top <= middleOfViewport && rect.bottom >= middleOfViewport) {
        return section.id;
      }
    }

    return '';
  }, [memoizedSections]);

  useEffect(() => {
    const handleScroll = () => setActiveItem(determineActiveSection());
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [determineActiveSection]);

  const isActive = (item) => (activeItem === item ? 'is-active' : '');

  const onLogin = (e) => {
    history.push('/login');
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="landing navigation"
    >
      <div className="navbar-brand">
        <div className="navbar-item">
          <img
            src={logo}
            alt="Logo"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="is-clickable"
          />
        </div>
        <div className="navbar-item select-language pr-0 is-hidden-tablet">
          <LanguageSelect i18n={i18n} />
        </div>
        <button
          className={`navbar-burger ${isBurgerActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarItems"
          onClick={() => setBurgerActive(!isBurgerActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div
        id="navbarItems"
        className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => scrollToSection(section.id)}
              className={`button navbar-item ${isActive(section.id)}`}
              ref={(el) => (sectionRefs.current[section.id] = el)}
            >
              {t('navbar.' + section.key)}
            </button>
          ))}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="control has-icons-left">
              <LanguageSelect i18n={i18n} />
              <div className="icon is-small is-left">
                <FontAwesomeIcon icon={faGlobe} className="has-text-black" />
              </div>
            </div>
          </div>
          <div className="navbar-item">
            <button
              className="button is-primary mb-0"
              onClick={(e) => onLogin(e)}
            >
              {t('navbar.login')}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
