import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDiets,
  createDiet,
  editDiet,
  deleteDiets,
  createPlan,
  createStandardPlan,
} from 'api/diets/index';
import thunkHandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const initialState = {
  list: [],
  error: false,
};

const fetchDiets = createAsyncThunk(
  'diets/fetchDiets',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkHandler(
      getDiets,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, []);
  },
);

const postDiet = createAsyncThunk(
  'diets/postDiets',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkHandler(
      createDiet,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const patchDiet = createAsyncThunk(
  'diets/patchDiets',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkHandler(
      editDiet,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const deleteDiet = createAsyncThunk(
  'diets/deleteDiets',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkHandler(
      deleteDiets,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const submitPlan = createAsyncThunk(
  'diets/submitPlan',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const callback = payload.meals_file ? createPlan : createStandardPlan;
    const response = await thunkHandler(
      callback,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, []);
  },
);

const dietSlice = createSlice({
  name: 'diets',
  initialState: initialState,
  reducers: {
    // Your reducers here, if any
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiets.fulfilled, (state, action) => {
        state.error = false;
        state.list = action.payload;
      })
      .addCase(fetchDiets.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(postDiet.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(patchDiet.fulfilled, (state, action) => {
        const newList = state.list.filter(
          (diet) => diet.id !== action.payload.id,
        );
        newList.push(action.payload);
        state.list = newList;
      })
      .addCase(deleteDiet.fulfilled, (state, action) => {
        state.list = state.list.filter((diet) => diet.id !== action.payload.id);
      })
      .addCase(submitPlan.fulfilled, (state, action) => {
        state.list = action.payload;
      });
    // Add more cases as needed
  },
});

export const fetchDietsThunk = fetchDiets;
export const postDietThunk = postDiet;
export const patchDietThunk = patchDiet;
export const deleteDietThunk = deleteDiet;
export const submitPlanThunk = submitPlan;
export const dietsReducer = dietSlice.reducer;
