import React from 'react';
import { NavLink } from 'react-router-dom';
import './_NavLinks.scss';

const AdminNavLinks = ({ closeDrawer }) => {
  const navItems = [
    { path: '/admin/patients', label: 'PACIENTES' },
    { path: '/admin/professionals', label: 'PROFESIONALES' },
    { path: '/admin/health-facilities', label: 'CENTROS MEDICOS' },
  ];

  return (
    <>
      {navItems.map((item, index) => (
        <li key={index}>
          <NavLink to={item.path} onClick={closeDrawer}>
            {item.label}
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default AdminNavLinks;
