import React from 'react';
import { useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';

import { removeToken } from 'helpers/token';
import { endSession } from 'features/user/userSlice';
import { resetChatbotState } from 'features/chatbot/chatbotSlice';

/**
 * Manages an idle timeout modal that prompts the user after a specified period of inactivity.
 * If the user is idle past the timeout, their session is ended and they are logged out.
 * The modal provides options to continue the session or to log out immediately.
 */

const IdleModal = ({ modal, setModal, timeout }) => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(endSession());
    dispatch(resetChatbotState());
    removeToken();
    window.location = '/';
  };

  if (!modal) return null;
  return (
    <div className="modal is-active">
      <IdleTimer element={document} onIdle={() => logout()} timeout={timeout} />
      <div className="modal-background" onClick={() => setModal(!modal)} />
      <div className="modal-wider modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Sigues ahí?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => setModal(!modal)}
          ></button>
        </header>

        <section className="modal-card-body">
          <p>
            Llevas 10 minutos inactivo. Por tu seguridad tu sesión será cerrada
            automáticamente en 1 minuto. Pulsa "Sigo Aquí" para continuar o
            "Cerrar Sesión" si deseas salir.
          </p>
        </section>
        <footer className="modal-card-foot">
          <div className="buttons right-buttons">
            <button
              className="button is-pulled-right right-buttons"
              type="button"
              onClick={() => setModal(!modal)}
            >
              Sigo Aquí
            </button>
            <button
              className="button is-danger"
              onClick={() => {
                setModal(!modal);
                logout();
              }}
            >
              Cerrar Sesión
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

IdleModal.prototypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  timeout: PropTypes.number.isRequired,
};

export default IdleModal;
