import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCountries,
  getRegions,
  getRegionsByCountry,
  getCommunes,
  getCommunesByRegion,
  getHealthFacilitiesByCommune,
} from 'api/locations';

import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const locationsState = {
  countries: {
    error: false,
    loading: false,
    list: [],
    current: null,
  },
  regions: {
    error: false,
    loading: false,
    list: [],
    current: null,
  },
  communes: {
    error: false,
    loading: false,
    list: [],
    current: null,
    healthFacilities: [],
  },
};

// COUNTRIES
const fetchCountries = createAsyncThunk(
  'locations/fetchCountries',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCountries,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.countries.list,
    );
  },
);

const fetchRegionsByCountry = createAsyncThunk(
  'locations/fetchRegionsByCountry',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getRegionsByCountry,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.regions.list,
    );
  },
);

// REGIONS
const fetchRegions = createAsyncThunk(
  'locations/fetchRegions',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getRegions,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.regions.list,
    );
  },
);

const fetchCommunesByRegion = createAsyncThunk(
  'locations/fetchCommunesByRegion',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCommunesByRegion,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.communes.list,
    );
  },
);

// COMMUNES
const fetchCommunes = createAsyncThunk(
  'locations/fetchCommunes',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCommunes,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.communes.list,
    );
  },
);

const fetchHealthFacilitiesByCommune = createAsyncThunk(
  'locations/fetchHealthFacilitiesByCommune',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getHealthFacilitiesByCommune,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(
      response.status,
      response.data,
      locationsState.communes.healthFacilities,
    );
  },
);

// Some helper functions for the reducers
const resetRegion = (state) => {
  state.regions.current = null;
  state.regions.healthFacilities = [];
};

const resetCommune = (state) => {
  state.communes.current = null;
  state.communes.healthFacilities = [];
};

// SLICE
const locationsSlice = createSlice({
  name: 'locations',
  initialState: locationsState,
  reducers: {
    cleanCountry: (state) => {
      state.countries.current = null;
      state.regions.list = [];
      resetRegion(state);
      resetCommune(state);
    },
    cleanRegion: (state) => {
      resetRegion(state);
      resetCommune(state);
    },
    cleanCommune: (state) => {
      resetCommune(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.countries.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries.list = action.payload;
        state.countries.loading = false;
        state.countries.error = false;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countries.loading = false;
        state.countries.error = true;
      })
      .addCase(fetchRegions.pending, (state, action) => {
        state.regions.loading = true;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.regions.list = action.payload;
        state.regions.loading = false;
        state.regions.error = false;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.regions.loading = false;
        state.regions.error = true;
      })
      .addCase(fetchCommunes.pending, (state, action) => {
        state.communes.loading = true;
      })
      .addCase(fetchCommunes.fulfilled, (state, action) => {
        state.communes.list = action.payload;
        state.communes.loading = false;
        state.communes.error = false;
      })
      .addCase(fetchCommunes.rejected, (state, action) => {
        state.communes.loading = false;
        state.communes.error = true;
      })
      .addCase(fetchRegionsByCountry.pending, (state, action) => {
        state.regions.loading = true;
      })
      .addCase(fetchRegionsByCountry.fulfilled, (state, action) => {
        state.regions.list = action.payload;
        state.regions.error = false;
        state.regions.loading = false;
        resetRegion(state);
        resetCommune(state);
        const currentCountryId = action.meta.arg;
        const currentCountry = state.countries.list.find(
          (country) => country.id === currentCountryId,
        );
        state.countries.current = currentCountry;
      })
      .addCase(fetchRegionsByCountry.rejected, (state, action) => {
        state.regions.error = true;
        state.regions.loading = false;
      })
      .addCase(fetchCommunesByRegion.pending, (state, action) => {
        state.communes.loading = true;
      })
      .addCase(fetchCommunesByRegion.fulfilled, (state, action) => {
        state.communes.list = action.payload;
        state.communes.error = false;
        state.communes.loading = false;
        state.communes.current = null;
        const currentRegionId = action.meta.arg;
        const currentRegion = state.regions.list.find(
          (region) => region.id === currentRegionId,
        );
        state.regions.current = currentRegion;
      })
      .addCase(fetchCommunesByRegion.rejected, (state, action) => {
        state.communes.error = true;
        state.communes.loading = false;
      })
      .addCase(fetchHealthFacilitiesByCommune.pending, (state, action) => {
        state.communes.loading = true;
      })
      .addCase(fetchHealthFacilitiesByCommune.fulfilled, (state, action) => {
        state.communes.error = false;
        state.communes.loading = false;
        state.communes.healthFacilities = action.payload;
        const currentCommuneId = action.meta.arg;
        const currentCommune = state.communes.list.find(
          (commune) => commune.id === currentCommuneId,
        );
        state.communes.current = currentCommune;
      })
      .addCase(fetchHealthFacilitiesByCommune.rejected, (state, action) => {
        state.communes.error = true;
        state.communes.loading = false;
      });
  },
});

export const { cleanCountry, cleanRegion, cleanCommune } =
  locationsSlice.actions;

export const fetchCountriesThunk = fetchCountries;
export const fetchRegionsByCountryThunk = fetchRegionsByCountry;
export const fetchRegionsThunk = fetchRegions;
export const fetchCommunesByRegionThunk = fetchCommunesByRegion;
export const fetchCommunesThunk = fetchCommunes;
export const fetchHealthFacilitiesByCommuneThunk =
  fetchHealthFacilitiesByCommune;
export const locationsReducer = locationsSlice.reducer;
