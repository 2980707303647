import React from 'react';

import './UpdateBanner.scss';

const UpdateBanner = ({ onConfirm, onDismiss }) => {
  return (
    <div id="update-notification">
      <div className="notification">
        <button className="button is-ghost" onClick={onConfirm}>
          Hay una nueva versión disponible! Presione para actualizar ahora.
        </button>
        <button className="delete" onClick={onDismiss}></button>
      </div>
    </div>
  );
};

export default UpdateBanner;
