import http from '../http';

export async function getDiets(payload) {
  const meals = await http.get(`patients/${payload}/meals`);
  return meals;
}

export async function createDiet(payload) {
  return await http.post('meals', payload);
}

export async function editDiet(payload) {
  const path = `meals/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteDiets(payload) {
  const path = `meals/${payload}`;
  return await http.delete(path);
}

export async function createPlan(payload) {
  const { file_type, period_type, ...rest } = payload;
  const formData = new FormData();

  // Ensure file is correctly appended if it's part of the payload
  if (payload.file) {
    formData.append('file', payload.file);
  }

  Object.keys(rest).forEach((key) => {
    formData.append(key, rest[key]);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await http.post('meals/files', formData, {
      params: { file_type, period_type },
      ...config,
    });
    return response;
  } catch (error) {
    console.error('Error creating plan:', error);
    throw error;
  }
}

export async function createStandardPlan(payload) {
  const { file_type, period_type, ...rest } = payload;
  return await http.post('meals/files', rest, {
    params: {
      file_type,
      period_type,
    },
  });
}

export async function getFile(payload) {
  const response = await http.get('meals/files/download', {
    blob: true,
    params: {
      file_type: payload,
    },
  });
  return response;
}
