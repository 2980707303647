import http from '../http';
export async function createPrescription(payload) {
  return await http.post('prescriptions', payload);
}

export async function getPrescriptions(payload) {
  const { patientId, from, to, status } = payload;
  const path = `patients/${patientId}/prescriptions`;
  return await http.get(path, {
    params: {
      from,
      to,
      status,
    },
  });
}

export async function getIntakes(payload) {
  const path = `prescriptions/${payload}/intakes`;
  return await http.get(path);
}

export async function deletePrescription(payload) {
  return await http.delete(`prescriptions/${payload}`);
}

export async function patchPrescription(payload) {
  const { id, ...rest } = payload;
  return await http.patch(`prescriptions/${id}`, rest);
}

export async function getPatientIntakes(payload) {
  const { patientId, from, to } = payload;
  const path = `patients/${patientId}/intakes`;
  return await http.get(path, {
    params: {
      from,
      to,
    },
  });
}
