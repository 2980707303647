import axios from 'axios';
import { getToken } from 'helpers/token';
import { showToast } from 'helpers/showToast';

/**
 * Axios Interceptors Configuration:
 *
 * This module configures Axios interceptors for a React application.
 * - Request interceptor adds an authorization token to headers if available.
 * - Response interceptor handles global error logging and displays toast notifications.
 * - Default base URL is set for API requests using environment variables.
 * - HTTP methods wrapper provides simplified GET, POST, PATCH, PUT, and DELETE requests.
 */

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function getErrorMessage(status) {
  switch (status) {
    case 400: //BAD REQUEST
      return 'No se pudo procesar su solicitud. Por favor, revise los datos e intente de nuevo.';
    case 401: //UNAUTHORIZED
      return 'Lo sentimos, ocurrió un problema.';
    case 403: //FORBIDDEN
      return 'Acceso denegado. No tiene permisos para realizar esta acción.';
    default:
      return 'Lo sentimos, ocurrió un problema. Por favor, intente de nuevo más tarde.';
  }
}

axios.interceptors.response.use(
  (response) => response, // Pass through on success
  (error) => {
    const status = error.response ? error.response.status : null;
    const message = getErrorMessage(status);
    showToast(message, 'is-danger');
    return Promise.reject(error);
  },
);

const http = {
  get: (url, options = {}) =>
    (options.blob &&
      axios.get(url, {
        responseType: 'blob',
        ...defaultOptions,
        ...options,
      })) ||
    axios.get(url, { ...defaultOptions, ...options }),
  post: (url, data, options = {}) =>
    axios.post(url, data, { ...defaultOptions, ...options }),
  patch: (url, data, options = {}) =>
    axios.patch(url, data, { ...defaultOptions, ...options }),
  put: (url, data, options = {}) =>
    axios.put(url, data, { ...defaultOptions, ...options }),
  delete: (url, options = {}) =>
    axios.delete(url, { ...defaultOptions, ...options }),
};

export default http;
