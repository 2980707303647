import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLogs } from 'api/logger/index';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const loggerState = {
  error: false,
  loading: false,
  data: {
    results: [],
  },
};

const fetchLogs = createAsyncThunk(
  'logs/fetchLogs',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getLogs,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, loggerState.data);
  },
);

const loggerSlice = createSlice({
  name: 'logger',
  initialState: loggerState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = false;
        state.loading = false;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const fetchLogsThunk = fetchLogs;
export const loggerReducer = loggerSlice.reducer;
