import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPrescription,
  getPrescriptions,
  deletePrescription,
  patchPrescription,
} from 'api/prescriptions/';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const prescriptionsState = {
  current: [],
  previous: [],
  byDate: [],
  error: false,
};

const fetchPrescriptions = createAsyncThunk(
  'prescriptions/fetchPrescriptions',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getPrescriptions,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, []);
  },
);

const postPrescription = createAsyncThunk(
  'prescriptions/postPrescription',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      createPrescription,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const removePrescription = createAsyncThunk(
  'prescriptions/deletePrescription',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const { data } = await thunkhandler(
      deletePrescription,
      payload.id,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return data;
  },
);
const resetPrescription = createAsyncThunk(
  'prescriptions/resetPrescription',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const { index, status, ...rest } = payload;
    const response = await thunkhandler(
      patchPrescription,
      rest,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    if (response.status === 200) {
      return [index, status, response.data];
    }
    return errorHandler(response.status, response.data);
  },
);
const suspendPrescription = createAsyncThunk(
  'prescriptions/suspendPrescription',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const { index, ...rest } = payload;
    const response = await thunkhandler(
      patchPrescription,
      rest,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    if (response.status === 200) {
      return response.data;
    }
    return errorHandler(response.status, response.data);
  },
);
const prescriptionsSlice = createSlice({
  name: 'prescriptions',
  initialState: prescriptionsState,
  reducers: {
    clearPrescriptions: (state) => {
      state.current = [];
      state.previous = [];
      state.error = false;
    },
    // Other reducers...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrescriptions.fulfilled, (state, action) => {
        state.error = false;
        const arg = action.meta.arg;
        if (arg.status === 'current') {
          state.current = action.payload;
        } else if (arg.status === 'previous') {
          state.previous = action.payload;
        } else if (arg.from || arg.to) {
          state.byDate = action.payload;
        }
      })
      .addCase(fetchPrescriptions.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(postPrescription.fulfilled, (state, action) => {
        state.current.push(action.payload);
      })
      .addCase(removePrescription.fulfilled, (state, action) => {
        if (action.meta.arg.status === 'current') {
          state.current = state.current.filter(
            (prescription) => prescription.id !== action.payload.id,
          );
        } else
          state.previous = state.previous.filter(
            (prescription) => prescription.id !== action.payload.id,
          );
      })
      .addCase(resetPrescription.fulfilled, (state, action) => {
        const [index, status, object] = action.payload;
        if (status === 'current') {
          state.current[index] = object;
        } else {
          state.previous = state.previous.filter(
            (prescription) => prescription.id !== action.payload.id,
          );
          state.current.push(object);
        }
      })
      .addCase(suspendPrescription.fulfilled, (state, action) => {
        state.current = state.current.filter(
          (prescription) => prescription.id !== action.payload.id,
        );
        state.previous.push(action.payload);
      });
    // Add more cases as needed
  },
});

export const postPrescriptionThunk = postPrescription;
export const fetchPrescriptionsThunk = fetchPrescriptions;
export const removePrescriptionsThunk = removePrescription;
export const resetPrescriptionsThunk = resetPrescription;
export const suspendPrescriptionsThunk = suspendPrescription;
export const { clearPrescriptions } = prescriptionsSlice.actions;
export const prescriptionsReducer = prescriptionsSlice.reducer;
