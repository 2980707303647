export const sortBrands = (a, b) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  return nameA > nameB ? 1 : -1;
};

export const sortMeds = (a, b) => {
  // Compare active ingredients
  const activeIngredientA = a.active_ingredient.toLowerCase();
  const activeIngredientB = b.active_ingredient.toLowerCase();
  if (activeIngredientA > activeIngredientB) return 1;
  if (activeIngredientA < activeIngredientB) return -1;

  // Compare presentations if active ingredients are the same
  if (a.presentation > b.presentation) return 1;
  if (a.presentation < b.presentation) return -1;

  // Compare dosages if presentations are also the same
  const dosageA = parseInt(a.dosage.split(' ')[0], 10);
  const dosageB = parseInt(b.dosage.split(' ')[0], 10);
  if (dosageA > dosageB) return 1;
  if (dosageA < dosageB) return -1;

  // Return 0 if all criteria are the same
  return 0;
};

export const sortByDate = (desc, a, b) => {
  //desc is for descending order
  return desc ? (a < b ? 1 : -1) : a > b ? 1 : -1;
};

export const sortCategories = (a, b) => {
  const elemA = (a.title || a.name).toUpperCase();
  const elemB = (b.title || b.name).toUpperCase();
  return elemA.localeCompare(elemB);
};
