import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getHealthFacilities,
  assignHealthFacilities,
  unassignHealthFacility,
} from 'api/admins';
import { getUser, editUser, getUsers } from 'api/users';
import {
  fetchHealthFacilityPatientsThunk,
  fetchHealthFacilityProfessionalsThunk,
} from '../medicalServices/medicalServicesSlice';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { updateProfessional } from 'features/professionals/professionalsSlice';

export const adminsState = {
  error: false,
  loading: false,
  list: [],
  selected: {
    error: false,
    loading: false,
    data: null,
    healthFacilities: [],
    allFacilityPatients: [],
    allFacilityProfessionals: [],
  },
};

const toggleAdminRole = createAsyncThunk(
  'admins/role',
  async (payload, thunkAPI) => {
    const { id, data } = payload;
    const token = thunkAPI.getState().user.data.refresh;
    const result = await thunkhandler(
      editUser,
      { id, ...data },
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    // Check if the user is an admin and update the Redux state accordingly
    if (result.data.is_health_facility_admin) {
      thunkAPI.dispatch(addAdmin(result.data));
    } else {
      thunkAPI.dispatch(removeAdmin(result.data));
    }

    if (result.data.is_professional) {
      thunkAPI.dispatch(updateProfessional(result.data));
    }

    return errorHandler(result.status, result.data);
  },
);

const fetchAdmins = createAsyncThunk(
  'admins/all',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const params = { is_health_facility_admin: true, light: true };
    const admins = await thunkhandler(
      getUsers,
      params,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(admins.status, admins.data, adminsState.list);
  },
);

const fetchAdmin = createAsyncThunk('admin', async (payload, thunkAPI) => {
  const token = thunkAPI.getState().user.data.refresh;
  let result = await thunkhandler(
    getUser,
    payload,
    thunkAPI,
    refreshSessionThunk,
    token,
  );
  return errorHandler(result.status, result.data);
});

const fetchAdminHealthFacilities = createAsyncThunk(
  'admin/health-facilities',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    let result = await thunkhandler(
      getHealthFacilities,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(result.status, result.data);
  },
);

const fetchAssignHealthFacilities = createAsyncThunk(
  'admins/assign-health-facility',
  async (payload, thunkAPI) => {
    const response = await assignHealthFacilities(payload);
    return errorHandler(response.status, response.data);
  },
);

const fetchUnassignHealthFacility = createAsyncThunk(
  'admins/unassign-health-facility',
  async (payload, thunkAPI) => {
    const response = await unassignHealthFacility(payload);
    return errorHandler(response.status, response.data);
  },
);

const fetchAllFacilityPatients = createAsyncThunk(
  'admin/all-facility-patients',
  async (payload, thunkAPI) => {
    const currentUser = thunkAPI.getState().user.data;
    let patients = [];
    let patientIds = new Set();

    for (const id of currentUser.health_facilities_managed) {
      const result = await thunkAPI.dispatch(
        fetchHealthFacilityPatientsThunk(id),
      );

      for (const patient of result.payload) {
        if (!patientIds.has(patient.id)) {
          patients.push(patient);
          patientIds.add(patient.id);
        }
      }
    }

    return patients;
  },
);

const fetchAllFacilityProfessionals = createAsyncThunk(
  'admin/all-facility-professionals',
  async (payload, thunkAPI) => {
    const currentUser = thunkAPI.getState().user.data;
    let professionals = [];
    let professionalIds = new Set();

    for (const id of currentUser.health_facilities_managed) {
      const result = await thunkAPI.dispatch(
        fetchHealthFacilityProfessionalsThunk(id),
      );

      for (const professional of result.payload) {
        if (!professionalIds.has(professional.id)) {
          professionals.push(professional);
          professionalIds.add(professional.id);
        }
      }
    }

    return professionals;
  },
);

// HELPER FUNCTIONS
const assignHealthFacilityHelper = (state, payload) => {
  const currentList = [...state.selected.healthFacilities];
  for (let newFacility of payload) {
    const alreadyOnList = currentList.some(
      (facility) => facility.id === newFacility.id,
    );

    if (!alreadyOnList) {
      currentList.push(newFacility);
    }
  }

  state.selected.healthFacilities = currentList;
};

const unassignHealthFacilityHelper = (state, payload) => {
  const unassignedItem = payload;
  const index = state.selected.healthFacilities.findIndex(
    (obj) => obj.id === unassignedItem.id,
  );
  state.selected.healthFacilities.splice(index, 1);
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState: adminsState,
  reducers: {
    addAdmin: (state, action) => {
      state.list = [...state.list, action.payload];
    },
    removeAdmin: (state, action) => {
      state.list = state.list.filter((admin) => admin.id !== action.payload.id);
    },
    updateAdmin: (state, action) => {
      // Find the index of the professional to update
      const index = state.list.findIndex(
        (admin) => admin.id === action.payload.id,
      );
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(fetchAdmin.pending, (state, action) => {
        state.selected.loading = true;
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        state.selected.data = action.payload;
        state.selected.loading = false;
        state.selected.error = false;
      })
      .addCase(fetchAdmin.rejected, (state, action) => {
        state.selected.data = null;
        state.selected.loading = false;
        state.selected.error = true;
      })
      .addCase(toggleAdminRole.fulfilled, (state, action) => {})
      .addCase(fetchAdminHealthFacilities.pending, (state, action) => {
        state.selected.loading = true;
      })
      .addCase(fetchAdminHealthFacilities.fulfilled, (state, action) => {
        state.selected.healthFacilities = action.payload;
        state.selected.loading = false;
        state.selected.error = false;
      })
      .addCase(fetchAdminHealthFacilities.rejected, (state, action) => {
        state.selected.healthFacilities = [];
        state.selected.loading = false;
        state.selected.error = true;
      })
      .addCase(fetchAssignHealthFacilities.fulfilled, (state, action) => {
        assignHealthFacilityHelper(state, action.payload);
      })
      .addCase(fetchUnassignHealthFacility.fulfilled, (state, action) => {
        unassignHealthFacilityHelper(state, action.payload);
      })
      .addCase(fetchAllFacilityPatients.pending, (state, action) => {
        state.selected.loading = true;
      })
      .addCase(fetchAllFacilityPatients.fulfilled, (state, action) => {
        state.selected.allFacilityPatients = action.payload;
        state.selected.loading = false;
        state.selected.error = false;
      })
      .addCase(fetchAllFacilityPatients.rejected, (state, action) => {
        state.selected.error = true;
        state.selected.loading = false;
      })
      .addCase(fetchAllFacilityProfessionals.pending, (state, action) => {
        state.selected.loading = true;
      })
      .addCase(fetchAllFacilityProfessionals.fulfilled, (state, action) => {
        state.selected.allFacilityProfessionals = action.payload;
        state.selected.error = false;
        state.selected.loading = false;
      })
      .addCase(fetchAllFacilityProfessionals.rejected, (state, action) => {
        state.selected.error = true;
        state.selected.loading = false;
      });
  },
});

export const { addAdmin, removeAdmin, updateAdmin } = adminsSlice.actions;

export const fetchAdminThunk = fetchAdmin;
export const fetchAdminsThunk = fetchAdmins;
export const toggleAdminRoleThunk = toggleAdminRole;
export const fetchAdminHealthFacilitiesThunk = fetchAdminHealthFacilities;
export const fetchAssignHealthFacilitiesThunk = fetchAssignHealthFacilities;
export const fetchUnassignHealthFacilityThunk = fetchUnassignHealthFacility;
export const fetchAllFacilityPatientsThunk = fetchAllFacilityPatients;
export const fetchAllFacilityProfessionalsThunk = fetchAllFacilityProfessionals;

export const adminsReducer = adminsSlice.reducer;
