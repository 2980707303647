import React from 'react';
import './_SideDrawer.scss';

import SuperAdminNavLinks from '../navLinks/SuperAdminNavLinks';
import AdminNavLinks from '../navLinks/AdminNavLiks';
import ProfessionalNavLinks from '../navLinks/ProfessionalNavLinks';
import StandaloneAdminNavLinks from '../navLinks/StandaloneAdminNavLinks';

const SideDrawer = ({ currentUser, isDrawerOpen, closeDrawer }) => {
  return (
    <div onClick={closeDrawer} className={`${isDrawerOpen ? 'mask' : ''}`}>
      <ul
        className={`side-drawer ${isDrawerOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        {currentUser.is_professional && (
          <ProfessionalNavLinks
            currentUser={currentUser}
            closeDrawer={closeDrawer}
          />
        )}

        {currentUser.is_superadmin && (
          <SuperAdminNavLinks closeDrawer={closeDrawer} />
        )}

        {currentUser.is_health_facility_admin && !currentUser.is_superadmin && (
          <AdminNavLinks closeDrawer={closeDrawer} />
        )}

        {currentUser.is_standalone_admin && (
          <StandaloneAdminNavLinks closeDrawer={closeDrawer} />
        )}
      </ul>
    </div>
  );
};

export default SideDrawer;
