import http from '../http';

export const getLogs = async (params) => {
  if (!params) {
    return http.get('logs');
  }

  // Make the GET request with params.
  return http.get('logs', { params });
};
