import http from '../http';

// COUNTRIES
export async function getCountries() {
  const countries = await http.get(`countries`);
  return countries;
}

export async function getCountry(payload) {
  const country = await http.get(`countries/${payload}`);
  return country;
}

export async function getRegionsByCountry(payload) {
  const regions = await http.get(`countries/${payload}/regions`);
  return regions;
}

export async function createCountry(payload) {
  return await http.post('countries', payload);
}

export async function editCountry(payload) {
  const path = `countries/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteCountry(payload) {
  const path = `countries/${payload}`;
  return await http.delete(path);
}

// REGIONS
export async function getRegions() {
  const regions = await http.get(`regions`);
  return regions;
}

export async function getCommunesByRegion(payload) {
  const communes = await http.get(`regions/${payload}/communes`);
  return communes;
}

export async function getRegion(payload) {
  const region = await http.get(`regions/${payload}`);
  return region;
}

export async function createRegion(payload) {
  return await http.post('regions', payload);
}

export async function editRegion(payload) {
  const path = `regions/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteRegion(payload) {
  const path = `regions/${payload}`;
  return await http.delete(path);
}

// COMMUNES
export async function getCommunes() {
  const communes = await http.get(`communes`);
  return communes;
}

export async function getCommune(payload) {
  const commune = await http.get(`communes/${payload}`);
  return commune;
}

export async function createCommune(payload) {
  return await http.post('communes', payload);
}

export async function editCommune(payload) {
  const path = `communes/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteCommune(payload) {
  const path = `communes/${payload}`;
  return await http.delete(path);
}

export async function getHealthFacilitiesByCommune(payload) {
  const healthFacilities = http.get(`communes/${payload}/health-facilities`);
  return healthFacilities;
}
