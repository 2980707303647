import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endSession } from 'features/user/userSlice';
import { removeToken } from 'helpers/token';
import { resetChatbotState } from 'features/chatbot/chatbotSlice';

import Navigation from './components/Navigation';
import Cover from './components/Cover';
import './styles.css';

// Lazy loading other components
const AboutUs = lazy(() => import('./components/AboutUs'));
const AboutMobile = lazy(() => import('./components/AboutMobile'));
const AboutWeb = lazy(() => import('./components/AboutWeb'));
const Characteristics = lazy(() =>
  import('./components/characteristics/Characteristics'),
);
const DataCollection = lazy(() => import('./components/DataCollection'));
const Team = lazy(() => import('./components/team/Team'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));

const LandingPage = () => {
  const user = useSelector((state) => state.user.data?.id);
  const dispatch = useDispatch();

  // logout
  useEffect(() => {
    if (user) {
      dispatch(resetChatbotState());
      dispatch(endSession());
      removeToken();
    }
  }, [user, dispatch]);

  return (
    <div id="landing-page">
      <Navigation />
      <Cover />
      <Suspense fallback={<div>Loading...</div>}>
        <AboutUs />
        <Characteristics />
        <AboutMobile />
        <AboutWeb />
        <Team />
        <Contact />
        <DataCollection />
        <Footer />
      </Suspense>
    </div>
  );
};

export default LandingPage;
