import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import NavLinks from './navLinks/NavLinks';
import logo from 'assets/img/logo-glik.webp';
import ProfileNavLinks from './userNavLinks/ProfileNavLinks';
import SideDrawer from './sideDrawer/SideDrawer';
import './_MainNavigation.scss';

const MainNavigation = ({ currentUser }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [navWidth, setNavWidth] = useState(0);
  const navLinksRef = useRef(null);

  const toggleDrawer = () => setIsDrawerOpen((current) => !current);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    if (navLinksRef.current) {
      setNavWidth(navLinksRef.current.clientWidth);
    }

    function checkOverflow() {
      const parentWidth = window.innerWidth;
      setIsOverflowing(navWidth + 370 > parentWidth);
    }

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [navWidth]);

  useEffect(() => {
    if (!isOverflowing && isDrawerOpen) {
      closeDrawer();
    }
  }, [isOverflowing, isDrawerOpen]);

  return (
    <>
      <nav className="main-header">
        <NavLink to="/home" className="navbar-logo">
          <img src={logo} alt="Logo" />
        </NavLink>

        {!isOverflowing && (
          <nav ref={navLinksRef}>
            <NavLinks currentUser={currentUser} isOverflowing={isOverflowing} />
          </nav>
        )}

        <ProfileNavLinks
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          isOverflowing={isOverflowing}
        />

        {isOverflowing && (
          <>
            <button
              className="main-navigation__menu-btn"
              onClick={toggleDrawer}
            >
              <span />
              <span />
              <span />
            </button>

            <SideDrawer
              currentUser={currentUser}
              isDrawerOpen={isDrawerOpen}
              closeDrawer={closeDrawer}
            />
          </>
        )}
      </nav>
    </>
  );
};

export default MainNavigation;
