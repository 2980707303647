import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  editUser,
  deleteUser,
  patchUserHealthFacilities,
  sendEmailVerification,
  createUser,
} from 'api/users';
import { removeMyPatient } from '../professionals/professionalsSlice';
import { removePatient } from '../patients/patientsSlice';
import { removeProfessional } from '../professionals/professionalsSlice';
import { removeSuperAdmin } from '../superAdmins/superAdminSlice';
import thunkhandler from 'helpers/thunk_handler';
import { errorHandler } from 'helpers/errorHandler';
import { removeAdmin } from 'features/admins/adminsSlice';
import { login } from 'api/auth/';
import { getUser } from 'api/users/index';
import { refresh } from 'api/auth/index';
import { setToken, removeToken } from 'helpers/token';

export const userState = {
  data: null,
  unauthorized: true,
  loading: false,
  error: '',
  message: '',
  updateAvailable: false,
};

// The first two functions are the only ones that don't call the
// thunk handler, that's why the logic is more complicated

const fetchUserInfo = createAsyncThunk(
  'users/fetchInfo',
  async (payload, thunkAPI) => {
    const { id, access, refresh } = payload;

    let response = null;
    try {
      response = await getUser(id);
    } catch (error) {
      response = error.response;
    }

    return errorHandler(response.status, { ...response.data, access, refresh });
  },
);

const fetchUser = createAsyncThunk(
  'users/fetchByCredentials',
  async (payload, thunkAPI) => {
    let response = null;
    try {
      response = await login(payload);
    } catch (error) {
      response = error.response;
    }

    const errorMessages = {
      400: 'Error en la verificación del CAPTCHA. Por favor, asegúrese de no estar en modo incógnito e intente nuevamente.',
      401: 'Rut y/o Contraseña Inválida',
      403: 'Su correo aún no ha sido confirmado. Por favor, confirme su dirección de correo e intente nuevamente.',
      503: 'Servicio temporalmente no disponible. Intente más tarde.',
    };

    // Get the error message based on the status, or use the default message if the status is not listed
    const errorMessage =
      errorMessages[response?.status] ||
      'Error desconocido. Intente nuevamente.';

    if (response?.status >= 400) {
      return Promise.reject(errorMessage);
    }

    return response.data;
  },
);

const refreshSession = createAsyncThunk(
  'users/refreshSession',
  async (payload, thunkAPI) => {
    const response = await refresh({
      refresh: payload,
    });
    return errorHandler(response.status, response.data);
  },
);

const registerUser = createAsyncThunk('user/new', async (payload, thunkAPI) => {
  const token = thunkAPI.getState().user.data.refresh;
  const response = await thunkhandler(
    createUser,
    payload,
    thunkAPI,
    refreshSessionThunk,
    token,
  );
  return errorHandler(response.status, response.data);
});

const editUserInfo = createAsyncThunk(
  'user/edit',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      editUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    response.status === 200 && thunkAPI.dispatch(refreshUser(response.data));

    return errorHandler(response.status, response.data);
  },
);

const removeUser = createAsyncThunk(
  'user/remove',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteUser,
      payload.id,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    switch (payload.userType) {
      case 'patient':
        thunkAPI.dispatch(removeMyPatient(payload.id));
        thunkAPI.dispatch(removePatient(payload.id));
        break;
      case 'professional':
        thunkAPI.dispatch(removeProfessional(payload.id));
        break;
      case 'admin':
        thunkAPI.dispatch(removeAdmin(payload.id));
        break;
      case 'superadmin':
        thunkAPI.dispatch(removeSuperAdmin(payload.id));
        break;
      default:
        break;
    }

    return response.data;
  },
);

const editUserHealthFacilities = createAsyncThunk(async (payload, thunkAPI) => {
  const token = thunkAPI.getState().user.data.refresh;
  const response = await thunkhandler(
    patchUserHealthFacilities,
    payload,
    thunkAPI,
    refreshSessionThunk,
    token,
  );
  return errorHandler(response.status, response.data);
});

const fetchSendEmailVerification = createAsyncThunk(
  'user/sendEmailVerification',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      sendEmailVerification,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    refreshUser: (state, action) => {
      const { access, refresh } = state.data;
      state.data = {
        ...action.payload,
        access,
        refresh,
      };
      state.unauthorized = false;
    },
    endSession: (state, action) => {
      state.data = null;
      state.unauthorized = true;
    },
    showUpdateAvailable: (state, action) => {
      state.updateAvailable = action.payload;
    },
    hideUpdateAvailable: (state, action) => {
      state.updateAvailable = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        setToken(action.payload.access);
        state.error = '';
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.data = null;
        state.unauthorized = true;
        state.loading = false;
      })
      .addCase(fetchUserInfo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        const user = action.payload;
        state.loading = false;

        if (
          !user.is_professional &&
          !user.is_health_facility_admin &&
          !user.is_superadmin
        ) {
          state.unauthorized = true;
          state.data = null;
        } else {
          state.unauthorized = false;
          state.data = user;
        }

        if (user.is_standalone_admin) {
          state.data = user;
          state.unauthorized = false;
        }

        if (user.is_standalone_user) {
          state.data = user;
        }

        state.error = state.unauthorized
          ? 'No dispone de los permisos necesarios para ingresar.'
          : '';
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.error = 'Error desconocido, intente nuevamente.';
        state.data = null;
        state.unauthorized = true;
        state.loading = false;
      })
      .addCase(refreshSession.fulfilled, (state, action) => {
        removeToken();
        setToken(action.payload.access);
        state.data.access = action.payload.access;
      })
      .addCase(editUserInfo.rejected, (state, action) => {
        state.error = '';
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.message = action.payload;
      })
      .addCase(editUserHealthFacilities.fulfilled, (state, action) => {
        state.message = 'User health facilities edited correctly';
      })
      .addCase(editUserHealthFacilities.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(fetchSendEmailVerification.fulfilled, (state, action) => {
        state.message = action.payload;
      })
      .addCase(fetchSendEmailVerification.rejected, (state, action) => {
        state.message = action.payload;
        state.error = true;
      });
  },
});

export const {
  refreshUser,
  endSession,
  showUpdateAvailable,
  hideUpdateAvailable,
} = userSlice.actions;

export const fetchUserThunk = fetchUser;
export const fetchInfoThunk = fetchUserInfo;
export const refreshSessionThunk = refreshSession;
export const registerUserThunk = registerUser;
export const editUserInfoThunk = editUserInfo;
export const removeUserThunk = removeUser;
export const editUserHealthFacilitiesThunk = editUserHealthFacilities;
export const fetchSendEmailVerificationThunk = fetchSendEmailVerification;
export const userReducer = userSlice.reducer;
