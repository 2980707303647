import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getUser, getUsers } from 'api/users';

import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const standaloneAdminsStates = {
  error: false,
  list: [],
  selected: null,
  isLoading: true,
  assignationMessage: '',
};

const fetchStandaloneAdmins = createAsyncThunk(
  'standalone-admins/all',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const params = { is_standalone_admin: true, is_light: true };
    const standaloneAdmins = await thunkhandler(
      getUsers,
      params,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(
      standaloneAdmins.status,
      standaloneAdmins.data,
      standaloneAdmins.list,
    );
  },
);

const fetchStandaloneAdmin = createAsyncThunk(
  'standaloneAdmin',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    let result = await thunkhandler(
      getUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(result.status, result.data);
  },
);

const standaloneAdminSlice = createSlice({
  name: 'standalone-admins',
  initialState: standaloneAdminsStates,
  reducers: {
    addStandaloneAdmin: (state, action) => {
      state.list = [...state.list, action.payload];
    },
    removeStandaloneAdmin: (state, action) => {
      state.list = state.list.filter((user) => user.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStandaloneAdmins.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchStandaloneAdmins.rejected, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(fetchStandaloneAdmin.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchStandaloneAdmin.rejected, (state, action) => {
        state.selected = null;
        state.isLoading = false;
      });
  },
});

export const fetchStandaloneAdminsThunk = fetchStandaloneAdmins;
export const fetchStandaloneAdminThunk = fetchStandaloneAdmin;
export const { addStandaloneAdmin, removeStandaloneAdmin } =
  standaloneAdminSlice.actions;
export const standaloneAdminsReducer = standaloneAdminSlice.reducer;
