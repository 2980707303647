import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getUser, editUser, getUsers } from 'api/users';

import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { updateProfessional } from 'features/professionals/professionalsSlice';
import { updateAdmin } from 'features/admins/adminsSlice';

export const superAdminsState = {
  error: false,
  list: [],
  selected: null,
  isLoading: true,
  assignationMessage: '',
};

const toggleSuperAdminRole = createAsyncThunk(
  'super-admins/role',
  async (payload, thunkAPI) => {
    const { id, data } = payload;
    const token = thunkAPI.getState().user.data.refresh;
    const result = await thunkhandler(
      editUser,
      { id, ...data },
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    if (result.data.is_superadmin) {
      thunkAPI.dispatch(addSuperAdmin(result.data));
    } else {
      thunkAPI.dispatch(removeSuperAdmin(id));
    }

    if (result.data.is_professional) {
      thunkAPI.dispatch(updateProfessional(result.data));
    }

    if (result.data.is_health_facility_admin) {
      thunkAPI.dispatch(updateAdmin(result.data));
    }

    return result.data;
  },
);

const fetchSuperAdmins = createAsyncThunk(
  'super-admins/all',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const params = { is_superadmin: true, is_light: true };
    const superAdmins = await thunkhandler(
      getUsers,
      params,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(
      superAdmins.status,
      superAdmins.data,
      superAdminsState.list,
    );
  },
);

const fetchSuperAdmin = createAsyncThunk(
  'superAdmin',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    let result = await thunkhandler(
      getUser,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(result.status, result.data);
  },
);

const superAdminsSlice = createSlice({
  name: 'super-admins',
  initialState: superAdminsState,
  reducers: {
    addSuperAdmin: (state, action) => {
      state.list = [...state.list, action.payload];
    },
    removeSuperAdmin: (state, action) => {
      state.list = state.list.filter((user) => user.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuperAdmins.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchSuperAdmins.rejected, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(fetchSuperAdmin.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchSuperAdmin.rejected, (state, action) => {
        state.selected = null;
        state.isLoading = false;
      })
      .addCase(toggleSuperAdminRole.fulfilled, (state, action) => {
        state.assignationMessage = 'La asignación ha sido exitosa';
      });
  },
});

export const fetchSuperAdminsThunk = fetchSuperAdmins;
export const toggleSuperAdminRoleThunk = toggleSuperAdminRole;
export const fetchSuperAdminThunk = fetchSuperAdmin;
export const { addSuperAdmin, removeSuperAdmin } = superAdminsSlice.actions;
export const superAdminsReducer = superAdminsSlice.reducer;
