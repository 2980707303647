import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCategories,
  getAdvices,
  postAdvice,
  deleteAdvice,
  editAdvice,
  postCategory,
  patchCategory,
  deleteCategory,
} from 'api/advices/index';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { sortCategories } from 'helpers/sort';

export const initialState = {
  error: false,
  categories: [],
  advices: {},
};

const fetchCategories = createAsyncThunk(
  'advCat/getCategories',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCategories,
      false,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const fetchAdvices = createAsyncThunk(
  'advCat/getAdvices',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getAdvices,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const createAdvice = createAsyncThunk(
  'advCat/createAdvice',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      postAdvice,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);
const editAdv = createAsyncThunk(
  'advCat/editAdvice',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      editAdvice,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const deleteAdv = createAsyncThunk(
  'advCat/deleteAdvice',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteAdvice,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const createCategory = createAsyncThunk(
  'advCat/createCategory',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      postCategory,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const editCategory = createAsyncThunk(
  'advCat/editCategory',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      patchCategory,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const deleteCat = createAsyncThunk(
  'advCat/deleteCategory',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteCategory,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const advicesSlice = createSlice({
  name: 'advcat',
  initialState: initialState,
  reducers: {
    getCurrent: (state, action) => {
      state.current.category = action.payload;
      const filterAdvices = state.advices.filter(
        (advice) => advice.category === action.payload,
      );
      state.current.advices = filterAdvices;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload.sort(sortCategories);

        const advices = {};
        state.categories.forEach((category) => {
          advices[category.id] = [];
        });
        state.advices = advices;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(fetchAdvices.fulfilled, (state, action) => {
        state.error = false;
        const advices = state.advices;
        if (action.payload.length) {
          advices[action.payload[0].category] = action.payload;
        }
        state.advices = advices;
      })
      .addCase(fetchAdvices.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(createAdvice.fulfilled, (state, action) => {
        const advices = state.advices;
        advices[action.payload.category].push(action.payload);
        state.advices = advices;
      })
      .addCase(editAdv.fulfilled, (state, action) => {
        const { payload } = action;
        const advices = state.advices;
        const filtered = advices[payload.category].filter(
          (advice) => advice.id !== payload.id,
        );
        filtered.push(payload);
        advices[payload.category] = filtered;
        state.advices = advices;
      })
      .addCase(deleteAdv.fulfilled, (state, action) => {
        const advices = state.advices;
        advices[action.payload.category] = advices[
          action.payload.category
        ].filter((advice) => advice.id !== action.payload.id);
        state.advices = advices;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
        const advices = state.advices;
        advices[action.payload.id] = [];
        state.advices = advices;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        const categories = state.categories;
        const filtered = categories.filter(
          (cat) => cat.id !== action.payload.id,
        );
        filtered.push(action.payload);
        state.categories = filtered;
      })
      .addCase(deleteCat.fulfilled, (state, action) => {
        state.categories = state.categories.filter(
          (cat) => cat.id !== action.payload.id,
        );
        const advices = state.advices;
        delete advices[action.payload.id];
        state.advices = advices;
      });
  },
});

export const advicesActions = advicesSlice.actions;
export const fetchCategoriesThunk = fetchCategories;
export const fetchAdvicesThunk = fetchAdvices;
export const createAdviceThunk = createAdvice;
export const editAdviceThunk = editAdv;
export const createCategoryThunk = createCategory;
export const deleteAdviceThunk = deleteAdv;
export const editCategoryThunk = editCategory;
export const deleteCategoryThunk = deleteCat;
export const advicesReducer = advicesSlice.reducer;
