const LanguageSelect = ({ i18n }) => {
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="select">
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        <option className="custom-option" value="en">
          En
        </option>
        <option className="custom-option" value="es">
          Es
        </option>
      </select>
    </div>
  );
};

export default LanguageSelect;
