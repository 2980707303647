import http from '../http';
import { getToken } from 'helpers/token';

export async function getUserConversations({ userId, skip, limit }) {
  const conversations = await http.get(
    `chatbot/users/${userId}?skip=${skip}&limit=${limit}`,
  );
  return conversations;
}

export async function getConversation(payload) {
  const conversation = await http.get(`chatbot/conversations/${payload}`);
  return conversation;
}

export async function createConversation(payload) {
  return await http.post('chatbot/conversations', payload);
}

export async function editConversation(payload) {
  const path = `chatbot/conversations/${payload.id}`;
  return await http.patch(path, payload);
}

export async function deleteConversation(payload) {
  const path = `chatbot/conversations/${payload}`;
  return await http.delete(path);
}

export async function getConversationMessages({ conversationId, skip, limit }) {
  const messages = await http.get(
    `chatbot/conversations/${conversationId}/messages?skip=${skip}&limit=${limit}`,
  );
  return messages;
}

export async function sendMessageToBot(
  payload,
  message,
  context,
  targetUser,
  callback,
  callbackFinally,
) {
  const authToken = getToken();
  const response = await fetch(
    `${process.env.REACT_APP_CHATBOT_URL}chatbot/conversations/${payload}/professionals/add_message`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      body: JSON.stringify({
        message,
        context,
        target_user: targetUser,
      }),
    },
  );

  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');

  reader
    .read()
    .then(function processResult(result) {
      if (result.done) return;
      let token = decoder.decode(result.value);
      callback(token); // Usamos un callback para enviar los mensajes al reducer de Redux
      return reader.read().then(processResult);
    })
    .finally(() => {
      callbackFinally();
    });
}

export async function sendFAQToBot(payload) {
  const { conversationId, ...body } = payload;
  const path = `chatbot/conversations/${conversationId}/frequently_questions`;
  return await http.patch(path, body);
}
