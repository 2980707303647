import http from '../http';

//HEALTH FACILITIES
export async function getHealthFacilities(payload) {
  const response = await http.get(`admins/${payload}/health-facilities`);
  return response;
}

export async function assignHealthFacilities(payload) {
  const { userId, healthFacilities } = payload;
  const response = await http.post(`admins/${userId}/health-facilities`, {
    health_facilities: healthFacilities,
  });
  return response;
}

export async function unassignHealthFacility(payload) {
  const { userId, healthFacility_id } = payload;
  const response = await http.delete(
    `admins/${userId}/health-facilities/${healthFacility_id}`,
  );
  return response;
}
