import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import reducer from './rootReducer';

let middlewares = []; // Start with an empty array of middlewares

// Only import and push redux-logger in development mode
if (process.env.REACT_APP_ENV === 'staging') {
  const { default: logger } = require('redux-logger');
  middlewares.push(logger);
}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

let persistor = persistStore(store);
const PersistedStore = { store, persistor };
export default PersistedStore;
