import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track the pageview with the path
    ReactGA.send({
      hitType: 'pageview',
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);

  return null;
};

export default RouteChangeTracker;
