import React from 'react';
import { NavLink } from 'react-router-dom';
import './_NavLinks.scss';

const ProfessionalNavLinks = ({ currentUser, closeDrawer }) => {
  const navItems = [
    { path: '/professional/patients', label: 'MIS PACIENTES' },
    {
      path: '/professional/health-facilities',
      label: 'CENTROS MEDICOS',
      condition: () =>
        !currentUser.is_health_facility_admin && !currentUser.is_superadmin,
    },
  ];

  return (
    <>
      {navItems
        .filter((item) => (item.condition ? item.condition() : true))
        .map((item, index) => (
          <li key={index}>
            <NavLink to={item.path} onClick={closeDrawer}>
              {item.label}
            </NavLink>
          </li>
        ))}
    </>
  );
};

export default ProfessionalNavLinks;
