import http from '../http';

//AUTH
export async function login(payload) {
  return await http.post('auth/login', payload);
}

export async function refresh(payload) {
  const response = await http.post('auth/refresh', payload);
  return response;
}

//PASSWORD
export const recoverPassword = async (rut) => {
  return await http.post('recover_password', {
    rut,
  });
};

export const requestPassword = async (payload) => {
  return await http.post('request_new_password', payload);
};
