import React from 'react';
import { useTranslation } from 'react-i18next';

const scrollToSection = (sectionId) => {
  document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
};

const Cover = () => {
  const { t } = useTranslation();

  return (
    <div id="header">
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="card">
            <h1 className="title">GLIK</h1>
            <p className="subtitle">{t('slogan')}</p>
            <button
              onClick={() => scrollToSection('about-us')}
              className="button is-link"
            >
              {t('learnMore')}
            </button>
          </div>
        </div>
        <div className="attribution">Imagen de vecstock en Freepik</div>
      </section>
    </div>
  );
};

export default Cover;
