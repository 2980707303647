import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTreatment, getPatientTreatments } from 'api/treatment/';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { getInfoThunk } from 'features/patients/patientsSlice';

const fetchPatientTreatments = createAsyncThunk(
  'treatment/fetchTreatments',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getPatientTreatments,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return response.data;
  },
);

const postTreatment = createAsyncThunk(
  'treatment/postTreatment',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      createTreatment,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    if (response && response.data) {
      await thunkAPI.dispatch(getInfoThunk({ id: response.data.patient }));
    }
    return response.data;
  },
);

export const fetchPatientTreatmentsThunk = fetchPatientTreatments;
export const postTreatmentThunk = postTreatment;
