import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import ReactGA from 'react-ga4';
import 'bulma/bulma.sass';

import Routes from '../routes';
import IdleModal from 'features/common/idleModal/idleModal';
import MainNavigation from 'features/navbar/MainNavigation';
import RouteChangeTracker from './RouteChangeTracker';
import UpdateBanner from '../features/common/updateBanner/UpdateBanner';
import { hideUpdateAvailable } from 'features/user/userSlice';
import './App.scss';

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

function App() {
  const [idle, setIdle] = useState(false);
  const timeout = 1000 * 60 * 10; // 10 mins
  const idleTimeout = 1000 * 60; // 1 min
  const userState = useSelector((state) => state.user);
  const hasAccess = userState.data?.id && !userState.unauthorized;

  const registration = useSelector((state) => state.user.updateAvailable);
  const dispatch = useDispatch();

  const updateServiceWorker = () => {
    // Instruct the waiting service worker to skip waiting and activate
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    // Dispatch an action to hide the update notifications
    dispatch(hideUpdateAvailable());
  };

  return (
    <Router>
      <RouteChangeTracker />
      {hasAccess && (
        <Fragment>
          <IdleTimer
            element={document}
            onIdle={() => setIdle(true)}
            timeout={timeout}
          />
          <MainNavigation currentUser={userState.data} />
        </Fragment>
      )}
      <IdleModal modal={idle} setModal={setIdle} timeout={idleTimeout} />
      {registration && (
        <UpdateBanner
          onConfirm={updateServiceWorker}
          onDismiss={() => dispatch(hideUpdateAvailable())}
        />
      )}
      <Routes />
    </Router>
  );
}

export default App;
