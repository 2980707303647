import { toast } from 'bulma-toast';

export const showToast = (message, type = 'is-success') => {
  toast({
    message: message,
    type: type,
    dismissible: true,
    pauseOnHover: true,
    duration: 5000,
  });
};
